import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PaymentAccount } from '../../../models';
import { Router } from '@angular/router';
import { WorkflowContextService } from '../../../services';

@Component({
  selector: 'wm-payment-account-list',
  templateUrl: './payment-account-list.component.html',
  styleUrls: ['./payment-account-list.component.css']
})
export class PaymentAccountListComponent implements OnInit {
  @Input() paymentAccounts: PaymentAccount[];
  @Input() allowSelect = true;
  @Input() showRemove = false;
  @Output() removed = new EventEmitter();

  constructor(
    private _router: Router,
    private _context: WorkflowContextService
  ) {}

  ngOnInit() {}

  editpaymentAccount(paymentAccount: PaymentAccount) {
    if (this.allowSelect) {
      this._router.navigate([
        'admin',
        'jurisdiction',
        this._context.client.id,
        'settings',
        'payment-settings',
        'payment-accounts',
        'edit',
        paymentAccount.id
      ]);
    }
  }
}
