<h3>Payment Settings</h3>

<ul ngbNav #paymentSettings="ngbNav" [destroyOnHide]="true" class="nav-tabs">
  <ng-container *ngIf="canManagePaymentAccounts">
    <li ngbNavItem>
      <a [routerLink]="['payment-accounts']" ngbNavLink>Payment Accounts</a>
      <ng-template ngbNavContent>
        <wm-payment-account-editor #paymentAccounts></wm-payment-account-editor>
      </ng-template>
    </li>
  </ng-container>
  <ng-container *ngIf="canManageFeeRecipients">
    <li ngbNavItem>
      <a [routerLink]="['fee-recipients']" ngbNavLink>Fee Recipients</a>
      <ng-template ngbNavContent>
        <wm-fee-recipients-editor #feeRecipients></wm-fee-recipients-editor>
      </ng-template>
    </li>
  </ng-container>
</ul>

<div class="mt-5" [ngbNavOutlet]="paymentSettings"></div>
