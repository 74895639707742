import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { DataEntity, ListDataEntity } from 'src/app/models/data-entities';
import {
  ConditionTarget,
  ConditionItem,
  LogicalOperator
} from './../../../../system/condition-builder/condition-builder.model';
import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { DataEntityEditorBaseComponent } from '../../data-entity-editor-base/data-entity-editor-base.component';
import {
  FeeDataEntity,
  FeeDataEntityType
} from '../../../../../models/data-entities';
import {
  WorkflowService,
  WorkflowContextService,
  SystemService,
  SecurityService
} from '../../../../../services';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { PaymentAccount, Actions } from '../../../../../models';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'wm-fee-data-entity-editor',
  templateUrl: './fee-data-entity-editor.component.html',
  styleUrls: ['./fee-data-entity-editor.component.css']
})
export class FeeDataEntityEditorComponent extends DataEntityEditorBaseComponent
  implements OnInit {
  constructor(
    @Inject(forwardRef(() => WorkflowService))
    public _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    public context: WorkflowContextService,
    @Inject(forwardRef(() => SystemService)) private _systemSvc: SystemService,
    @Inject(forwardRef(() => SecurityService))
    private _securitySvc: SecurityService,
    private _fb: UntypedFormBuilder
  ) {
    super();
  }

  FeeDataEntityType = FeeDataEntityType;
  feeEntity: FeeDataEntity;
  paymentAccounts: PaymentAccount[];
  canConfigureAccount: boolean;

  feeType: FeeDataEntityType;
  listDataEntitiesBefore: DataEntity[];
  listDeValues = [];
  listFeeOtherwise: number;
  listFeeAmounts: { [key: string]: number } = {};
  selectedPaymentAccount: PaymentAccount;
  useRecipientFromList: boolean;
  selectedClientFeeRecipientId: string;
  canManageFeeRecipients = false;

  public changeFeeType(popover: NgbPopover) {
    const newType = parseFloat(this.feeType.toString());
    const oldType = parseFloat(this.feeEntity.type.toString());

    if (newType < oldType) {
      this.updateFormula([]);
      this.listFeeAmounts = {};
      this.listFeeOtherwise = null;
    }
    this.feeEntity.type = newType;

    popover.close();
  }

  get wf() {
    return this.workflow || this.context.workflow;
  }

  closeModal() {
    this.closeRequested.emit(true);
  }

  updateFormula(e) {
    (<FeeDataEntity>this.dataEntity).formulaCriteria = e;
    this.feeEntity.formulaCriteria = e;
    this.updated.emit(this.feeEntity);
  }

  ngOnInit() {
    this.feeEntity = this.dataEntity as FeeDataEntity;
    this.feeType = this.feeEntity.type || FeeDataEntityType.Simple;
    this.useRecipientFromList =
      !this.feeEntity.feeRecipient || this.feeEntity.feeRecipient === '';
    this.selectedClientFeeRecipientId = this.feeEntity.clientFeeRecipient
      ? this.feeEntity.clientFeeRecipient.id
      : null;

    if (!this.feeEntity.clientFeeRecipient) {
      this.feeEntity.clientFeeRecipient = null;
    }

    if (!this.feeEntity.formulaCriteria) {
      this.feeEntity.formulaCriteria = [];
    }

    this._securitySvc
      .isLoginEntitled(Actions.MANAGE_FEE_RECIPIENTS)
      .subscribe(r => {
        this.canManageFeeRecipients = r;
      });

    this.form.addControl(
      'canAdminEdit',
      this._fb.control(false, [Validators.nullValidator])
    );
    this.form.addControl(
      'type',
      this._fb.control(FeeDataEntityType.Simple, [Validators.nullValidator])
    );
    this.form.addControl(
      'listTemplateCode',
      this._fb.control(this.feeEntity.listTemplateCode, [
        Validators.nullValidator
      ])
    );
    this.form.addControl(
      'paymentAccount',
      this._fb.control(false, [Validators.nullValidator])
    );
    this.form.addControl(
      'feeRecipient',
      this._fb.control(false, [Validators.nullValidator])
    );
    this.form.addControl(
      'availableFeeRecipients',
      this._fb.control(false, [Validators.nullValidator])
    );
    this.form.addControl(
      'feeMinimum',
      this._fb.control(false, [Validators.nullValidator])
    );
    this.form.addControl(
      'feeMaximum',
      this._fb.control(false, [Validators.nullValidator])
    );

    if (this.context.client) {
      forkJoin(
        this._securitySvc
          .isLoginEntitled(Actions.PAYMENT_PROCESSOR_ACCOUNT_MANAGE)
          .pipe(
            map(e => {
              this.canConfigureAccount = e;
            })
          ),
        this._systemSvc.getPaymentAccounts(this.context.client.id).pipe(
          map(accounts => {
            this.paymentAccounts = accounts;
          })
        ),
        this._workflowSvc.getDataEntitiesBeforeMe(this.wf, this.activity).pipe(
          map(des => {
            this.listDataEntitiesBefore = des.filter(de => {
              return (
                de.dataEntityTypeCode ===
                WorkflowService.DATA_ENTITIES.ListData.code
              );
            });
          })
        )
      ).subscribe();

      this.getListItems();
    }

    if (this.feeEntity.type.toString() === FeeDataEntityType.List.toString()) {
      this.conditionsToList();
    }

    // this.changeDetRef.detectChanges();
  }

  /**
   * Translate a key/value list of fees into condition targets
   */
  private listToConditions() {
    const conditions: ConditionTarget[] = [];
    let order = 0;
    for (const key in this.listFeeAmounts) {
      if (this.listFeeAmounts[key]) {
        const target = new ConditionTarget({
          value: this.listFeeAmounts[key].toString(),
          group: new ConditionItem({
            isGroup: true,
            logicalOperator: LogicalOperator.And,
            conditions: [
              new ConditionItem({
                field: this.feeEntity.listTemplateCode,
                formulaOperator: '~',
                val: key
              })
            ]
          }),
          order
        });

        conditions.push(target);

        order += 1;
      }
    }

    if (this.listFeeOtherwise) {
      conditions.push(
        new ConditionTarget({
          isOtherwise: true,
          value: this.listFeeOtherwise.toString(),
          group: new ConditionItem({
            isGroup: true,
            logicalOperator: LogicalOperator.And
          }),
          order: 0
        })
      );
    }

    return conditions;
  }

  /**
   * Translate condition targets into a key/value list of fees
   */
  private conditionsToList() {
    for (const condition of this.feeEntity.formulaCriteria) {
      if (condition.isOtherwise) {
        this.listFeeOtherwise = parseFloat(condition.value);
      } else {
        const key = condition.group.conditions[0].val.toString();
        const value = parseFloat(condition.value);

        this.listFeeAmounts[key] = value;
      }
    }
  }

  public listFeesChange() {
    this.updateFormula(this.listToConditions());
  }

  public getListItems() {
    this.feeEntity.listTemplateCode = this.form.controls[
      'listTemplateCode'
    ].value;
    if (
      this.feeEntity.type === FeeDataEntityType.List &&
      this.feeEntity.listTemplateCode
    ) {
      this._workflowSvc
        .getWorkflowDataEntityByCode(this.wf, this.feeEntity.listTemplateCode)
        .subscribe((de: ListDataEntity) => {
          if (de) {
            this.listDeValues = de.availableListItems;
          }
        });
    }
  }

  public isType(value: string | number, type: FeeDataEntityType) {
    return value.toString() === type.toString();
  }

  // Use => in order to force `this` into being the FeeDataEntityEditorComponent
  getThenLabel = (clause: ConditionTarget): string => {
    if (clause) {
      return clause.value;
    } else {
      return '(unset)';
    }
  };

  isFeeAccountConfigured() {
    if (
      this.feeEntity &&
      this.paymentAccounts &&
      this.feeEntity.paymentAccountId
    ) {
      const acct = this.paymentAccounts.find(
        pa => pa.id === this.feeEntity.paymentAccountId
      );

      return !acct ? false : acct.isConfigured;
    }

    return false;
  }

  setRecipientType(useRecipientFromList: boolean) {
    this.useRecipientFromList = useRecipientFromList;

    if (useRecipientFromList) {
      this.feeEntity.feeRecipient = null;
    }
    if (!useRecipientFromList) {
      this.feeEntity.clientFeeRecipient = null;
      this.selectedClientFeeRecipientId = null;
    }
  }

  setClientFeeRecipient() {
    const selectedClientFeeRecipient = this.feeEntity.availableFeeRecipients.find(
      afr => afr.id === this.selectedClientFeeRecipientId
    );

    this.feeEntity.clientFeeRecipient = JSON.parse(
      JSON.stringify(selectedClientFeeRecipient)
    );
  }
}
