export class FeeRecipient {
  id: string;
  value: string;
  clientId: string;

  public constructor(options?: Partial<FeeRecipient>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class FeeRecipientGenerationImpactReport {
  impactedWorkflows: WorkflowItem[];
}

export class WorkflowItem {
  draftVersionId: string;
  workflowName: string;
  lastPendingChangesMade: string;
                      // [activityId]: templateCodes
  feeEntityActivities: { [activityId: string]: string[] }
}

export class FeeRecipientGenerationRequest {
  workflowFeeRecipientItems: WorkflowFeeRecipientItem[]

  public constructor(options?: Partial<FeeRecipientGenerationRequest>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class WorkflowFeeRecipientItem {
  draftVersionId: string;
  activityId: string;
  templateCode: string;

  public constructor(options?: Partial<WorkflowFeeRecipientItem>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class FeeRecipientGenerationResponse {
  success: boolean;
  newFeeRecipients: FeeRecipient[];
}
