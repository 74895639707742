import { Permission } from './permission';

export class Action {
  id: string;
  name: string;
  description: string;
  permissions: Permission[];

  constructor(options?: Partial<Action>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class Actions {
  static DO_ANYTHING = 'A080A5DE-725E-4EA2-9590-F1495DD67740';

  static APPLY_ON_BEHALF_OF = '82133309-F807-497E-9E46-5A1BB07874F7';

  static WORKFLOW_ADD = '684FE424-98A8-4794-8574-C43064F481F0';
  static WORKFLOW_EDIT = '7899B8C8-3143-4C2A-9588-6E348100B7DF';
  static WORKFLOW_VIEW = '8B038FEE-5425-492B-83C6-242876EBA4A7';
  static WORKFLOW_APPLICATIONS_VIEW = 'C895E264-91F3-469C-97DD-4A4E683205EE';
  static WORKFLOW_APPLICATIONS_EDIT = 'F9B33A56-9DC9-4D53-AED2-AC34438C50C7';
  static WORKFLOW_APPLICATIONS_VOID = 'BA2A1F30-138B-466E-82EE-53504AA0A012';

  static WORKFLOW_VIEW_INTERNAL = 'FE8B3366-4CA7-448E-91AA-F7646D79C525';

  static CLIENTS_MANAGE = '1CC112B6-46E0-47EF-86AE-91ECDC9278A1';
  static CLIENTS_MANAGE_SETTINGS = '2394CD6E-A61D-4218-AD37-2EA538DFB445';

  static USER_MANAGE = 'CA093464-C5FB-4277-9994-24A551E5124F';

  static MAP_SERVICE_MANAGE = 'DC49759C-4377-43F8-8326-D7E525680FE4';

  static PAYMENT_PROCESSOR_MANAGE = '8B84866C-E3E3-460D-BB6E-1DDF0ADC5C95';

  static PAYMENT_PROCESSOR_ACCOUNT_MANAGE =
    '5E3D13C5-248B-43F2-BF81-201B916F7AF1';

  static EXTERNAL_CONNECTION_MANAGE = '2BF65342-912E-4002-80E2-912241BAF621';

  static ROLE_MANAGE = 'E4A4CCAC-346D-4526-9048-682C5BF6B259';

  static REPORT_PAYMENT_TRANSACTION_VIEW =
    '914FA258-F809-4231-BD2E-225EAD1B3A1C';
  static REPORT_DATA_EXPORT_VIEW = 'C9508316-2C29-48C5-A074-7823FE1E1E26';

  static CONTRACTORS_MANAGE = '6CE852F6-6CBA-4B17-80C5-B9C8F3495733';
  static ADMIN = 'EF5DC4C4-C3F7-485C-91C3-4C3839BA779D';
  static VIEW_INSPECTIONS = '0DB861C5-DBA6-47A8-8478-23371DB234E2';

  static VIEW_PREVIOUS_SYSTEM_DATA = '810294B8-51E7-4C96-9D21-C0036CB80960';
  static VIEW_CUSTOM_REPORTS = '9CEC7657-5AD8-4835-B7AE-A9C87C3D0964';

  static VIEW_AGENDAS = '8702C697-3457-47BB-A819-56A0A8DF11EF';
  static MANAGE_AGENDAS = '5A6672FC-FF99-4C92-83FC-1B4D4BFF6569';

  static AGENDA_ACTIONS = [Actions.VIEW_AGENDAS, Actions.MANAGE_AGENDAS];
  static INVITE_USER = 'DB570509-EF94-4F43-A6CF-57DE76C1AD08';
  static EDIT_COMPLETED_APPLICATIONS = '9BA5A397-DD5A-40F4-840B-81FB9CD28236';
  static REOPEN_COMPLETED_APPLICATION = '11A92233-4A0D-4A17-9177-9B438BE711B6';
  static EDIT_APPLICATION_NUMBER = '8EE138FE-435A-48E5-B48D-0419A38A1927';

  static WORKFLOW_APPLICATION_ACTIONS = [
    Actions.WORKFLOW_APPLICATIONS_EDIT,
    Actions.WORKFLOW_APPLICATIONS_VIEW,
    Actions.EDIT_APPLICATION_NUMBER
  ];

  static WORKFLOW_ACTIONS = [
    Actions.WORKFLOW_ADD,
    Actions.WORKFLOW_VIEW,
    Actions.WORKFLOW_EDIT,
    ...Actions.WORKFLOW_APPLICATION_ACTIONS
  ];

  static REVOKE_APPLICATION_SHARE = '0BC1D8AD-7888-4A35-99E6-F5F58B7F4BDC';

  static VIEW_PERMIT_SUMMARY = '516DC873-3519-4FEB-B164-5A5F497A75B6';
  static VIEW_CONTRACTOR_SUMMARY = '6C9203A3-D039-44AC-A68F-D109CDC06F6E';
  static VIEW_CONTRACTOR_FEE_SUMMARY = '18826F43-1BDA-48AF-B5BC-27D65C67AE40';

  static CONTRACTOR_TYPE_APPROVE = '5B1D5793-6AF4-43DE-91C7-13074942791C';
  static CONTRACTOR_TYPE_DENY = 'BCE974C7-CC98-49D5-AA29-8ACB0E8D028C';
  static CONTRACTOR_TYPE_RENEW = '0034EAFF-6F25-4764-AB01-F2D32B060187';
  static CONTRACTOR_TYPE_VIEW_HISTORY = '7CB2A299-05C1-4936-A727-F05BF97A46C4';
  static REFUND_PAYMENT = '11D86386-01CE-4EAE-8767-EF2812269105';

  static RENEWALS_VIEW = '6F94E1EB-3A45-45A3-8B02-56CBC5E9B633';
  static VIEW_USER_GUIDE = 'DB69C230-21CB-4D4F-8DF3-CCA8D19434CE';
  static RENEWALS_APPROVE = '068302F5-0759-4726-AA83-4E4B6D0A1281';
  static RENEWALS_DENY = '6C9330AC-D0FE-43CD-AE25-157A2125AB7F';
  static RENEWALS_RENEW = '65869CDA-8C6A-4E18-9B42-12C66D93BDD9';
  static VIEW_INTERNAL_PARCEL_NOTES = '15BB0C03-4433-4302-B473-48DB42916B59';
  static MANAGE_PARCEL_NOTES = '71B14589-5DB5-4930-89AC-1E578E741784';
  static VIEW_APPLICATION_VERSION = '67F4C36A-E0F4-4093-B542-6C5E47A7DD5E';

  static MANAGE_SCHEDULED_EXPORTS = 'CCCEE327-13CC-4AE6-8CF1-6D7A5EDB4B50';
  static MANAGE_DATASETS = '4BB409CD-7CE1-4E62-8CE0-725CD4468ABB';

  static VIEW_EMAILS = '243A937A-2808-45E0-AF19-376DA6FF40D5';
  static RESEND_EMAILS = '7D96C16C-73EB-4710-AEC0-C1E282F4354D';
  static EDIT_PREVIOUS_SYSTEM_DATA = '020AF3FF-10CD-48E1-A280-CF44E557B958';
  static MANAGE_FEE_RECIPIENTS = 'DD1AB1BC-0B85-4E65-8823-98C9BCD3F950';
  static GENERATE_FEE_RECIPIENTS = 'B5F86961-EA86-440B-8E9D-BAD323073179';

  static INSPECTION_ACTIONS = [Actions.VIEW_INSPECTIONS];

  static CONTRACTOR_ACTIONS = [
    Actions.CONTRACTORS_MANAGE,
    Actions.CONTRACTOR_TYPE_APPROVE,
    Actions.CONTRACTOR_TYPE_DENY,
    Actions.CONTRACTOR_TYPE_RENEW,
    Actions.CONTRACTOR_TYPE_VIEW_HISTORY
  ];

  static CLIENT_ADMIN_ACTIONS = [
    ...Actions.WORKFLOW_ACTIONS,
    ...Actions.CONTRACTOR_ACTIONS,
    Actions.PAYMENT_PROCESSOR_ACCOUNT_MANAGE,
    Actions.REPORT_DATA_EXPORT_VIEW,
    Actions.REPORT_PAYMENT_TRANSACTION_VIEW,
    Actions.ROLE_MANAGE,
    Actions.CLIENTS_MANAGE_SETTINGS,
    Actions.MANAGE_DATASETS,
    ...Actions.INSPECTION_ACTIONS
  ];

  // actions a role should have in order to be responsible for an activity
  static RESPONSIBLE_ROLE_ACTIONS = [Actions.WORKFLOW_APPLICATIONS_EDIT];

  // this is initialized from the server at application load
  static ActionInformation: {
    [key: string]: Partial<Action>;
  } = {};

  // These are actions that will give you access to the admin section of the system.  If you have any of these actions you will see the admin link
  static AdministratorActions: string[] = [Actions.ADMIN, Actions.WORKFLOW_ADD];

  static registerAction(action: Partial<Action>) {
    if (!Actions.ActionInformation[action.id]) {
      Actions.ActionInformation[action.id] = action;
    } else if (Actions.ActionInformation[action.id].name !== action.name) {
      throw {
        message:
          'A different action with that id has already been registered.  Choose a unique id for the action.'
      };
    }
  }
}
