<form [formGroup]="form" *ngIf="contractor && form">
  <div class="container mt-4">
    <app-loader *ngIf="!contractor"></app-loader>
    <ul ngbNav #nav="ngbNav" class="nav-tabs">
      <li ngbNavItem>
        <a ngbNavLink>Profile</a>
        <ng-template ngbNavContent>
          <div class="row mt-3">
            <div class="col">
              <h3>
                {{ contractor?.user?.userName }}
              </h3>
            </div>
            <div class="col">
              <button
                class="btn btn-raised btn-success float-right"
                (click)="save()"
                [disabled]="form.invalid || isSaving"
              >
              {{ isSaving ? 'Saving...' : 'Save' }}
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="card mt-4 mb-4">
                <div class="card-body">
                  <h6 class="card-title">Profile</h6>
                  <div class="form-group">
                    <label for="businessName" class="bmd-label-floating"
                      >Business Name</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="contractor.businessName"
                      name="businessName"
                      formControlName="businessName"
                    />
                  </div>
                </div>
              </div>

              <div class="card mt-4 mb-4">
                <div class="card-body">
                  <h6 class="card-title">Contact Info</h6>

                  <div class="form-group">
                    <label for="contactFirstName" class="bmd-label-floating"
                      >First Name</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="contractor.contactFirstName"
                      name="contactFirstName"
                      formControlName="contactFirstName"
                    />
                  </div>

                  <div class="form-group">
                    <label for="contactLastName" class="bmd-label-floating"
                      >Last Name</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="contractor.contactLastName"
                      name="contactLastName"
                      formControlName="contactLastName"
                    />
                  </div>

                  <div class="form-group">
                    <label for="emailAddress" class="bmd-label-floating"
                      >Email Address</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="contractor.contactEmail.email"
                      name="emailAddress"
                      formControlName="emailAddress"
                    />
                  </div>

                  <div class="form-group">
                    <label for="phoneNumber" class="bmd-label-floating"
                      >Contact Office Number</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="contractor.businessPhone.number"
                      name="phoneNumber"
                      formControlName="phoneNumber"
                    />
                  </div>

                  <div class="form-group">
                    <label for="cellNumber" class="bmd-label-floating"
                      >Contact Cell Number</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="contractor.businessCell.number"
                      name="cellNumber"
                      formControlName="cellNumber"
                    />
                  </div>

                  <div class="form-group">
                    <label for="faxNumber" class="bmd-label-floating"
                      >Fax Number</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="contractor.contactFaxNumber.number"
                      name="faxNumber"
                      formControlName="faxNumber"
                    />
                  </div>
                </div>
              </div>

              <div class="card mt-4 mb-4">
                <div class="card-body">
                  <h6 class="card-title">Additional Fields</h6>
                  <wm-contractor-custom-field-list
                    [customFields]="contractor.customFields"
                    [form]="form"
                    #customFields
                  ></wm-contractor-custom-field-list>
                </div>
              </div>

              <div class="card mt-4 mb-4">
                <div class="card-body">
                  <h6 class="card-title">Address</h6>

                  <div class="form-group">
                    <wm-address-detail
                      [address]="contractor.address"
                      [showButtons]="false"
                      [required]="false"
                      [showName]="false"
                    ></wm-address-detail>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col">
              <button
                class="btn btn-raised btn-success float-right"
                (click)="save()"
                [disabled]="form.invalid"
              >
                Save
              </button>
            </div>
          </div>
        </ng-template>
      </li>
      <li ngbNavItem>
        <a ngbNavLink>Registrations</a>
        <ng-template ngbNavContent>
          <div class="row mb-4">
            <div class="col" [formGroup]="newRegistrationForm">
              <wm-modal-confirm
                #newRegistration
                commandText="Start"
                buttonText="New Registration"
                [showButtonText]="true"
                title="New Contractor Registration"
                class="float-right"
                btnColor="success"
                [form]="newRegistrationForm"
                (opened)="startNewReg($event)"
                (accepted)="startApplication($event)"
                (canceled)="resetModal($event)"
              >
                <div class="form-group">
                  <label for="newRegClient" class="bmd-label-floating">
                    Jurisdiction
                  </label>
                  <select
                    class="form-control"
                    [(ngModel)]="newRegistrationClientId"
                    name="newRegClient"
                    formControlName="newRegClient"
                    (change)="selectClient($event)"
                  >
                    <option [ngValue]="null" disabled selected>
                      Select a jurisdiction
                    </option>
                    <ng-container *ngFor="let state of contractorClientStates">
                      <ng-container *ngIf="state && state !== ''">
                        <option [ngValue]="null" disabled selected>
                          &nbsp; &nbsp;
                          {{ state }}
                        </option>
                      </ng-container>
                      <option
                        *ngFor="
                          let client of contractorClients
                            | dynamicFilter: { state: state }
                        "
                        [value]="client.id"
                      >
                        {{ client.name }}
                      </option>
                    </ng-container>
                  </select>
                </div>

                <div class="form-group" *ngIf="newRegistrationTypes">
                  <label for="newRegContractorType" class="bmd-label-floating"
                    >Contractor Type</label
                  >
                  <div
                    *ngIf="
                      newRegistrationTypes && newRegistrationTypes.length == 0
                    "
                  >
                    <i>Please renew your current registrations</i>
                  </div>

                  <select
                    *ngIf="
                      newRegistrationTypes && newRegistrationTypes.length > 0
                    "
                    class="form-control"
                    [(ngModel)]="newRegistrationTypeId"
                    name="newRegClient"
                    formControlName="newRegContractorType"
                    (change)="selectType($event)"
                  >
                    <option [ngValue]="null" disabled selected>
                      Select a contractor type
                    </option>
                    <option
                      *ngFor="let type of newRegistrationTypes"
                      [value]="type.id"
                      >{{ type.name }}</option
                    >
                  </select>
                </div>
              </wm-modal-confirm>
            </div>
          </div>

          <wm-contractor-registration-list
            [id]="id"
            [clientId]="clientId"
            [initialColumns]="columns"
            [staticFilters]="filterOptions"
            [allowPermissibleActions]="contextClientId"
            [accessedFromContractorProfile]="true"
          >
          </wm-contractor-registration-list>
        </ng-template>
      </li>
      <li ngbNavItem>
        <a ngbNavLink>Documents</a>
        <ng-template ngbNavContent>
          <wm-contractor-document-list
            [documents]="documents"
            [documentCategories]="documentCategories"
            (saved)="saveDocument($event)"
            [parentId]="contractorId"
            (deleted)="deleteDocument($event)"
            (added)="addNewDocuments($event)"
          ></wm-contractor-document-list>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</form>
