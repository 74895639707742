import { CustomList, CustomListItem } from './../models/custom-list';
import { Injectable, Inject, forwardRef } from '@angular/core';
import { WorkflowContextService } from './workflow-context.service';
import { DataService, SaveEmailRequest, SaveEmailResponse } from './data.service';
import { Client } from '../models/client';
import { Contact } from '../models/contact';
import { Observable, empty } from 'rxjs';
import {
  ContactAddress,
  ContactEmailAddress,
  ClientSearchOptions
} from '../models';
import { Utilities } from './utilities';
import { FeeRecipient, FeeRecipientGenerationImpactReport, FeeRecipientGenerationRequest, FeeRecipientGenerationResponse } from '../models/fee-recipient';
import { ValidateDomainResponse } from '../models/validate-domain-response';

@Injectable()
export class ClientService {
  disableTestModeForCustomFromDomain(id: string): Observable<void> {
    return this._dataService.disableTestModeForCustomFromDomain(id);
  }
  enableTestModeForCustomFromDomain(id: string): Observable<void> {
    return this._dataService.enableTestModeForCustomFromDomain(id);
  }
  isDomainTestValidationEnabled(id: string): Observable<boolean> {
    return this._dataService.isDomainTestValidationEnabled(id);
  }
  saveEmailSettings(emailSettings: SaveEmailRequest): Observable<SaveEmailResponse> {
    return this._dataService.saveEmailSettings(emailSettings);
  }
  constructor(
    @Inject(forwardRef(() => DataService)) private _dataService: DataService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService
  ) {}

  changeClient(client: Client) {
    this._context.client$.next(client);
  }
  getClient(clientId: string): Observable<Client> {
    return this._dataService.getClient(clientId);
  }
  getPublicClient(clientId: string): Observable<Client> {
    return this._dataService.getPublicClient(clientId);
  }
  getContact(contactId: string): Observable<Contact> {
    return this._dataService.getContact(contactId);
  }
  getAdminClients(): Observable<Client[]> {
    return this._dataService.getAdminClients();
  }
  getClients(): Observable<Client[]> {
    return this._dataService.getClients();
  }

  getContacts(clientId: string): Observable<Contact[]> {
    return this._dataService.getContacts(clientId);
  }

  getFeeRecipients(clientId: string): Observable<FeeRecipient[]> {
    return this._dataService.getFeeRecipients(clientId);
  }

  saveFeeRecipient(feeRecipient: FeeRecipient): Observable<FeeRecipient> {
    return this._dataService.saveFeeRecipient(feeRecipient);
  }

  deleteFeeRecipient(feeRecipientId: string): Observable<boolean> {
    return this._dataService.deleteFeeRecipient(feeRecipientId);
  }

  getFeeRecipientGenerationImpactReport(clientId: string): Observable<FeeRecipientGenerationImpactReport> {
    return this._dataService.getFeeRecipientGenerationImpactReport(clientId);
  }

  generateFeeRecipients(request: FeeRecipientGenerationRequest): Observable<FeeRecipientGenerationResponse> {
    return this._dataService.generateFeeRecipients(request);
  }

  getCustomLists(clientId: string): Observable<CustomList[]> {
    return this._dataService.getCustomLists(clientId);
  }

  getCustomList(customListId: string): Observable<CustomList> {
    return this._dataService.getCustomList(customListId);
  }

  saveCustomList(list: CustomList): Observable<CustomList> {
    return this._dataService.saveCustomList(list);
  }

  deleteCustomList(customListId: string): Observable<void> {
    return this._dataService.deleteCustomList(customListId);
  }

  saveCustomListItem(
    customListId: string,
    item: CustomListItem
  ): Observable<CustomListItem> {
    return this._dataService.saveCustomListItem(customListId, item);
  }

  deleteCustomListItem(customListId: string, itemId: string): Observable<void> {
    return this._dataService.deleteCustomListItem(customListId, itemId);
  }

  searchClients(options: ClientSearchOptions): Observable<Client[]> {
    return this._dataService.searchClients(options);
  }
  saveClient(client: Client): Observable<Client> {
    return this._dataService.saveClient(client);
  }
  disableClient(client: Client): Observable<Client> {
    return this._dataService.disableClient(client);
  }
  removeClient(client: Client) {}
  createClient(): Client {
    return new Client({
      id: '',
      name: '',
      adminRole: {
        id: '',
        name: '',
        client: null,
        members: [],
        actions: []
      },
      parcelDataSource: '',
      state: '',
      visibleToPublic: false
    });
  }
  createContact(): Contact {
    return new Contact({
      id: Utilities.EMPTY_GUID,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      emailAddressId: null,
      emailAddress: new ContactEmailAddress(),
      addressId: null,
      address: new ContactAddress(),
      title: ''
    });
  }
  getClientsWithContractors(): Observable<Client[]> {
    return this._dataService.getClientsWithContractors();
  }

  saveContact(contact: Contact): Observable<Contact> {
    return this._dataService.saveContact(contact);
  }
  removeContact(contact: Contact): Observable<Contact> {
    return this._dataService.removeContact(contact);
  }
  validateCustomFromDomain(clientId: string): Observable<ValidateDomainResponse> {
    return this._dataService.validateCustomFromDomain(clientId);
  }
}
