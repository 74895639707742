<div class="bmd-layout-container bmd-drawer-f-l bmd-drawer-in">
  <div id="dw-s1" class="bmd-layout-drawer bg-faded admin-sidebar no-print">
    <header>
      <a class="navbar-brand">
        <i
          class="material-icons"
          style="cursor: pointer"
          (click)="backToList()"
        >
          arrow_back
        </i>
        {{
          _context.workflow?.version?.name
            ? _context.workflow?.version?.name
            : 'Workflow Name'
        }}
      </a>
    </header>

    <div class="btn-group" role="group" aria-label="Workflow actions">
      <button
        *ngFor="let control of _diagramCtrls.controls"
        class="btn"
        [ngClass]="['btn-' + control.color]"
        (click)="control.click()"
        [disabled]="control.disabled()"
        [attr.aria-label]="control.name"
        [ngbTooltip]="control.name"
        placement="top"
      >
        <i class="material-icons">{{ control.icon }}</i>
      </button>
    </div>
    <app-loader *ngIf="availableActivities.length < 1"></app-loader>
    <ul class="list-group scroll" *ngIf="!isReadOnly()">
      <a
        *ngFor="let ai of availableActivities"
        class="list-group-item flex-column align-items-start draggable-item"
        [id]="'activity-item-' + ai.type"
      >
        <h6>{{ ai.name }}</h6>
        <p>{{ ai.description }}</p>
      </a>
    </ul>
    <div *ngIf="isReadOnly()" class="text-danger pl-2 font-weight-bold">
      <div *ngIf="_appDiagramInfo" class="pb-2">
        Application Number: {{ _appDiagramInfo?.applicationNumber || 'N/A' }}
      </div>
      <p *ngIf="_appDiagramInfo">
        This is a read-only view of the specific version of the workflow being
        used by the selected application. The colored activity borders allow you
        to visualize the activities that were Completed (green), Canceled
        (pink), or In Progress (blue).
      </p>
      <p>
        DISCLAIMER: Any changes that you are able to make in this designer will
        not be saved. This designer should be used to see if the version is
        designed as expected for this application or gather details to provide
        to the development team when troubleshooting an application.
      </p>
    </div>
  </div>
  <header class="bmd-layout-header"></header>
  <main class="no-print-margin bmd-layout-content">
    <div class="container-fluid">
      <div [class]="isReadOnly() ? 'graphcontainerreadonly' : 'graphcontainer'">
        <div
          #diagram
          id="diagram"
          class="base"
          [ngStyle]="{ opacity: loaded ? 1 : 0 }"
        ></div>

        <div
          class="row"
          style="position: relative; left: 30px;width: 290px !important;"
          *ngIf="_context.workflow"
        >
          <div
            class="col"
            style="background-color: white; border-bottom-left-radius: 1em; border-bottom-right-radius: 1em;padding-bottom: 5px"
            [ngStyle]="{
              borderBottomLeftRadius: workflowSearchVisible ? '0' : '1em',
              borderBottomRightRadius: workflowSearchVisible ? '0' : '1em',
              paddingBottom: workflowSearchVisible ? '0' : '5px'
            }"
          >
            <div class="row">
              <div class="col-2">
                <label class="col-form-label" for="sequence">View</label>
              </div>
              <div class="col-8">
                <select
                  class="form-control"
                  id="sequence"
                  [ngModel]="_context.workflow.designStatus"
                  (change)="onChangeDiagram($event)"
                >
                  <option value="1">Application Steps</option>
                  <option value="2">Post Application Steps</option>
                </select>
              </div>
              <div class="col-2 mt-2">
                <button
                  type="button"
                  class="btn btn-primary bmd-btn-icon"
                  (click)="toggleWorkflowSearch()"
                  ngbTooltip="Search"
                >
                  <i class="material-icons">
                    search
                  </i>
                </button>
              </div>
            </div>

            <div class="row">
              <div
                class="col mt-1"
                *ngIf="parentChildWorkflows.parentWorkflows.length > 0"
              >
                <label class="col-form-label" for="sequence"
                  >Parent Workflows</label
                >
                <div [formGroup]="dataMappingForm">
                  <select
                    id="addMappingSource"
                    name="addMappingSource"
                    class="form-control"
                    required
                    formControlName="addMappingSource"
                    (change)="navigateToWorkflow($event)"
                  >
                    <option value="1" selected disabled>
                      Navigate To:
                    </option>
                    <option
                      *ngFor="
                        let entity of parentChildWorkflows.parentWorkflows
                      "
                      [value]="entity.activityId"
                    >
                      {{ entity.sourceWorkflowName }} {{ entity.activityName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!-- nobody asked for or wanted, but it provides a way to navigate to child workflows.  always hidden on purpose because nobody liked it -->
            <div class="row">
              <div
                class="col mt-5"
                *ngIf="parentChildWorkflows.childWorkflows.length > 0 && 1 == 0"
              >
                <label class="col-form-label" for="sequence"
                  >Child Workflows</label
                >
                <div [formGroup]="dataMappingForm">
                  <select
                    id="addMappingSource"
                    name="addMappingSource"
                    class="form-control"
                    required
                    formControlName="addMappingSource"
                    [(ngModel)]="newMapping.targetWorkflow"
                    (change)="navigateToWorkflow($event)"
                  >
                    <option value="1" selected disabled>
                      Navigate To:
                    </option>
                    <option
                      *ngFor="let entity of parentChildWorkflows.childWorkflows"
                      [ngValue]="entity.targetWorkflowId"
                    >
                      {{ entity.targetWorkflowName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="_appDiagramInfo">
              <div class="col-4">
                <label class="col-form-label">Iterations</label>
              </div>
              <div class="col-7">
                <select
                  class="form-control"
                  [(ngModel)]="viewingIteration"
                  (ngModelChange)="displayAppStatus().subscribe()"
                >
                  <option></option>
                  <option *ngFor="let o of _appDiagramInfo.iterations">{{
                    o
                  }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div
          style="position: relative; left: 15px; min-width: 290px;background-color: white; border-top-right-radius: 1em; border-bottom-left-radius: 1em; border-bottom-right-radius: 1em;padding-bottom: 5px;padding-left: 15px;padding-bottom: 15px; height: 92%;max-width: 83%"
          *ngIf="workflowSearchVisible"
        >
          <div class="row">
            <div class="col-10">
              <form (submit)="searchWorkflow(searchInput)" novalidate>
                <input
                  placeholder="Search"
                  name="searchInput"
                  class="form-control"
                  [(ngModel)]="searchInput"
                />
              </form>
            </div>
            <div class="col-2 mt-2">
              <button
                type="button"
                class="btn btn-sm btn-primary"
                (click)="searchWorkflow(searchInput)"
                ngbTooltip="Search"
              >
                Go
              </button>
            </div>
          </div>
          <div class="row" style="height: 100%;">
            <div
              class="col"
              style="max-height: 91%; max-width: 96%; overflow-y: auto;overflow-x: hidden"
            >
              <ul class="list-group" class="p-0 mb-0" *ngIf="searchResults">
                <li *ngIf="isSearching">Searching...</li>
                <li
                  class="list-group-item"
                  style="cursor: pointer;"
                  *ngFor="let item of searchResults"
                  (click)="navigateToSearchItem(item)"
                >
                  <span>{{ item.templateCode }} ({{ item.activityName }})</span>
                </li>
                <li *ngIf="searchResults.length == 0">
                  <span>Nothing found in workflow</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="mt-5">
          <app-loader *ngIf="!loaded"></app-loader>
        </div>

        <div
          class="spinner-border spinner-border-lg loading"
          role="status"
          *ngIf="loaded && graphLoading"
        >
          <span class="sr-only">Loading...</span>
        </div>

        <div id="outline" class="no-print"></div>
        <div
          class="spinner-border spinner-border-sm saving"
          role="status"
          *ngIf="saving || pretendToBeSaving"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <wm-workflow-activity-sidebar
      [workflow]="_context.workflow"
      #wfActivitySidebar
    >
    </wm-workflow-activity-sidebar>
  </main>
</div>

<wm-modal-confirm
  *ngIf="_context.workflow"
  [showButton]="false"
  moduleId="debugInfo"
  id="debugInfo"
  title="Debug Information"
  #debugInfoModal
>
  <pre>{{ debugInformation }}</pre>
</wm-modal-confirm>

<wm-modal-confirm
  *ngIf="_context.workflow"
  [showButton]="false"
  moduleId="appActivityData"
  id="appActivityData"
  title="Application Activity Data"
  #appActivityDataModal
>
  <h4 *ngIf="appActivities">{{ appActivities[0].model.screenName }}</h4>
  <ng-container *ngIf="appActivities">
    <ul ngbNav #appNav="ngbNav" [activeId]="viewingIteration?.toString()">
      <li
        [ngbNavItem]="a.applicationIteration.toString()"
        *ngFor="let a of appActivities"
        [id]="a.applicationIteration.toString()"
      >
        <a ngbNavLink>{{ 'Iteration ' + a.applicationIteration.toString() }}</a>
        <ng-template ngbNavContent>
          <h5>Status: {{ a.status | activityStatus }}</h5>
          <h5>Completed On: {{ a.completedOn | date: 'M/d/yyyy h:mm a' }}</h5>
          <h5>Completed By: {{ a.completedBy }}</h5>
          <div class="card">
            <div class="card-body">
              <wm-activity-view
                [activity]="a"
                [form]="appDebugForm[a.activityDataId]"
                *ngIf="a"
              >
              </wm-activity-view>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="appNav" class="mt-2"></div>
  </ng-container>
</wm-modal-confirm>

<wm-modal-confirm
  *ngIf="_context.workflow"
  commandText="Publish"
  [showButton]="false"
  [moduleId]="_context.workflow.id + 'publish'"
  [id]="_context.workflow.id"
  [title]="'Publish Workflow'"
  (accepted)="publishWorkflow()"
  (opened)="publishModalOpened()"
  [form]="publishForm"
  #publishModal
>
  <p *ngIf="isValidating" class="text-danger">
    Validating workflow...
  </p>
  <p *ngIf="!isValidating && publishForm.invalid" class="text-danger">
    Workflow has errors, please review the errors in the
    <a href="javascript:void(0)" (click)="dismissPublish()">
      Workflow Builder
    </a>
  </p>
  <p
    *ngIf="!isValidating && !publishForm.invalid && warningsForm.invalid"
    class="text-warning"
  >
    Workflow is valid, but with warnings. Please review the warnings in the
    <a href="javascript:void(0)" (click)="dismissPublish()">
      Workflow Builder
    </a>
  </p>
  <p
    *ngIf="!isValidating && !publishForm.invalid && !warningsForm.invalid"
    class="text-success"
  >
    Workflow is valid
  </p>
  <br />
  <p>
    Are you sure you want to publish this workflow ({{
      _context.workflow.version.name
    }})?
  </p>
  <br />
  <p>
    Some things to take note of.
  </p>
  <ol>
    <li>
      All new Applications for this Workflow will use the new configuration.
    </li>
    <li>
      Existing Applications already in progress will continue to use the old
      configuration.
    </li>
    <li>
      Any renewable workflows that use this workflow as its renewal will also be
      published.
    </li>
    <li>
      Any workflow that is a renewal of this workflow will also be published.
    </li>
  </ol>
</wm-modal-confirm>

