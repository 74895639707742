<form [formGroup]="form" novalidate>
  <ng-template #popContent>
    <p
      class="text-warning"
      *ngIf="
        feeType < feeEntity.type ||
        (isType(feeEntity.type, FeeDataEntityType.Simple) &&
          feeType.toString() !== feeEntity.type.toString())
      "
    >
      Switching to this fee type will DELETE the configuration for this fee.
    </p>

    <div class="form-group">
      <label class="bmd-label-static" for="type">
        Type
      </label>
      <select
        class="form-control"
        [(ngModel)]="feeType"
        formControlName="type"
        name="type"
      >
        <option [value]="FeeDataEntityType.Simple">Simple</option>
        <option [value]="FeeDataEntityType.List">List</option>
        <option [value]="FeeDataEntityType.Advanced">Advanced</option>
      </select>
    </div>

    <div class="d-flex justify-content-between align-items-center mt-3">
      <button
        type="button"
        class="btn btn-raised btn-secondary mr-1"
        (click)="p.close(); feeType = feeEntity.type"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-raised btn-primary ml-1"
        (click)="changeFeeType(p)"
      >
        Okay
      </button>
    </div>
  </ng-template>

  <div
    class="btn btn-raised btn-primary"
    placement="bottom"
    [ngbPopover]="popContent"
    popoverTitle="Change Fee Type"
    [autoClose]="'outside'"
    #p="ngbPopover"
  >
    Change Type
  </div>

  <!-- Simple fee type -->
  <ng-container *ngIf="isType(feeEntity.type, FeeDataEntityType.Simple)">
    <wm-data-entity-formula
      id="feeFormula"
      [criteria]="feeEntity.formulaCriteria"
      [thenLabelGetter]="getThenLabel"
      (criteriaChanged)="updateFormula($event)"
      [formulaTitle]="'fee'"
      [workflow]="workflow"
      [activity]="activity"
      [overrideAdvancedFee]="false"
      [allowTypeChange]="false"
    ></wm-data-entity-formula>
  </ng-container>

  <!-- List fee type -->
  <ng-container *ngIf="isType(feeEntity.type, FeeDataEntityType.List)">
    <app-loader *ngIf="!listDataEntitiesBefore"></app-loader>
    <div
      class="form-group"
      *ngIf="listDataEntitiesBefore && listDataEntitiesBefore.length > 0"
    >
      <label class="bmd-label-floating" for="type">
        List Data Entity
      </label>
      <select
        class="form-control"
        (change)="getListItems()"
        formControlName="listTemplateCode"
        name="listTemplateCode"
      >
        <option
          *ngFor="let de of listDataEntitiesBefore"
          [value]="de.templateCode"
        >
          {{ de.templateCode }}
        </option>
      </select>
    </div>
    <p
      *ngIf="listDataEntitiesBefore && listDataEntitiesBefore.length < 1"
      class="text-warning"
    >
      Please create at least one List Data Entity before this Data Entity.
    </p>

    <table class="table" *ngIf="listDeValues && listDeValues.length > 0">
      <thead>
        <tr>
          <th scope="col">List Item</th>
          <th scope="col">Fee Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let option of listDeValues">
          <th scope="row" class="align-middle">
            {{ option.text }}
          </th>
          <td class="align-middle">
            <input
              class="form-control form-control-number"
              [min]="0"
              type="number"
              [name]="'fee-' + option.value"
              [id]="'fee-' + option.value"
              [(ngModel)]="listFeeAmounts[option.value]"
              [ngModelOptions]="{ standalone: true }"
              (ngModelChange)="listFeesChange()"
            />
          </td>
        </tr>
        <tr>
          <th scope="row" class="align-middle">
            Otherwise
          </th>
          <td class="align-middle">
            <input
              class="form-control form-control-number"
              [min]="0"
              type="number"
              name="fee-otherwise"
              id="fee-otherwise"
              [(ngModel)]="listFeeOtherwise"
              [ngModelOptions]="{ standalone: true }"
              (ngModelChange)="listFeesChange()"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <!-- Advanced fee type -->
  <ng-container *ngIf="isType(feeEntity.type, FeeDataEntityType.Advanced)">
    <wm-data-entity-formula
      id="feeFormula"
      [criteria]="feeEntity.formulaCriteria"
      [thenLabelGetter]="getThenLabel"
      (criteriaChanged)="updateFormula($event)"
      [formulaTitle]="'fee'"
      [workflow]="workflow"
      [activity]="activity"
      [overrideAdvancedFee]="true"
      [allowTypeChange]="false"
    ></wm-data-entity-formula>
  </ng-container>

  <div class="switch">
    <label>
      <input
        type="checkbox"
        [(ngModel)]="feeEntity.canAdminEdit"
        formControlName="canAdminEdit"
      />
      Admin can edit value
    </label>
  </div>

  <ng-container *ngIf="context.client">
    <div class="form-group">
      <label class="bmd-label-floating" for="feeMinimum">Minimum Fee</label>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="feeEntity.feeMinimum"
        formControlName="feeMinimum"
        name="feeMinimum"
      />
    </div>

    <div class="form-group">
      <label class="bmd-label-floating" for="feeMaximum">Maximum Fee</label>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="feeEntity.feeMaximum"
        formControlName="feeMaximum"
        name="feeMaximum"
      />
    </div>
    <div class="form-group">
      <label class="bmd-label-floating" for="paymentAccount">
        Payment Account (Online Payments)
      </label>
      <select
        class="form-control"
        [(ngModel)]="feeEntity.paymentAccountId"
        formControlName="paymentAccount"
        name="paymentAccount"
      >
        <option></option>
        <option [value]="a.id" *ngFor="let a of paymentAccounts">
          {{ a.name }} ({{ a.processor.name }})
          {{ a.isConfigured ? '' : ' - NOT CONFIGURED' }}
        </option>
      </select>
      <div
        *ngIf="
          !isFeeAccountConfigured() &&
          canConfigureAccount &&
          feeEntity.paymentAccountId
        "
      >
        <a
          [routerLink]="[
            '/admin/jurisdiction/',
            context.client.id,
            'settings',
            'payment-settings',
            'payment-accounts',
            'edit',
            feeEntity.paymentAccountId
          ]"
          (click)="closeModal()"
          >Configure</a
        >
      </div>
    </div>

    <div class="form-group">
      <!-- <label class="bmd-label-floating">Fee Recipient</label> -->
      <div class="row">
        <div class="col">
          Fee Recipient
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-6 text-left float-left">
          <div class="radio">
            <label>
              <input
                type="radio"
                name="recipientType"
                [value]="true"
                [(ngModel)]="useRecipientFromList"
                [ngModelOptions]="{ standalone: true }"
                [checked]="useRecipientFromList === true"
                (change)="setRecipientType(true)"
              />
              From list
            </label>
          </div>
        </div>
        <div class="col-6 text-left float-left">
          <div class="radio">
            <label>
              <input
                type="radio"
                name="recipientType"
                [value]="false"
                [(ngModel)]="useRecipientFromList"
                [ngModelOptions]="{ standalone: true }"
                [checked]="useRecipientFromList === false"
                (change)="setRecipientType(false)"
              />
              Manual
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <ng-container *ngIf="useRecipientFromList">
          <div class="col-8">
            <select
              class="form-control"
              [(ngModel)]="selectedClientFeeRecipientId"
              name="availableFeeRecipients"
              formControlName="availableFeeRecipients"
              (change)="setClientFeeRecipient()"
            >
              <option [ngValue]="null" disabled>(None selected)</option>
              <option
                *ngFor="let fr of feeEntity.availableFeeRecipients"
                [ngValue]="fr.id"
                >{{ fr.value }}</option
              >
            </select>
          </div>
          <div class="col-4 mt-1" *ngIf="canManageFeeRecipients">
            <a
              target="_blank"
              [routerLink]="[
                '/admin/jurisdiction/',
                context.client.id,
                'settings',
                'payment-settings',
                'fee-recipients'
              ]"
              >Manage List</a
            >
          </div>
        </ng-container>

        <ng-container *ngIf="!useRecipientFromList">
          <div class="col">
            <input
              type="text"
              class="form-control"
              [(ngModel)]="feeEntity.feeRecipient"
              formControlName="feeRecipient"
              name="feeRecipient"
            />
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</form>
