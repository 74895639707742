import { ConditionTarget } from 'src/app/components/system/condition-builder/condition-builder.model';
import { WorkflowValidityModalComponent } from './../../../../components/workflow/workflow-validity-modal/workflow-validity-modal.component';
import { WorkflowValidationService } from './../../../../services/workflow-validation.service';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import {
  WorkflowContextService,
  ContractorService,
  WorkflowService,
  Utilities,
  ActivityFactory,
  SystemService,
  ValidationService
} from '../../../../services';
import {
  ContractorType,
  ContractorTypeRequirement,
  Workflow,
  WorkflowType,
  ApplicationStatus
} from '../../../../models';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl
} from '@angular/forms';
import { isObject } from 'util';
import { DataEntity } from '../../../../models/data-entities';
import {
  DataEntityLayoutModel,
  FormActivity,
  PrintTemplateActivity,
  Activity,
  ActivityModel
} from '../../../../models/activities';
import { ToastrService } from 'ngx-toastr';
import { PreviousRouteService } from '../../../../services/previous-route';
import { Subscription, Observable, concat } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContractorTypeTag } from 'src/app/models/contractor-type-tag';
import { ItemTag } from 'src/app/models/item-tag';
import { Tag } from 'src/app/models/tag';
import { WorkflowGraph } from 'src/app/models/workflow-graph';
import { range } from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'wm-contractor-type-editor-view',
  templateUrl: './contractor-type-editor-view.component.html',
  styleUrls: ['./contractor-type-editor-view.component.css']
})
export class ContractorTypeEditorViewComponent implements OnInit, OnDestroy {
  contractorType: ContractorType;
  contractorTypeId: string;
  public contractorEntities: DataEntity[];
  availableContractorEntities: DataEntity[];
  public registrationEntities: DataEntity[];
  availableRegistrationEntities: DataEntity[];
  form: UntypedFormGroup;
  public registerEntities: DataEntity[];
  clientSubscription: Subscription;
  EMPTY_GUID = Utilities.EMPTY_GUID;
  newDocPriorActivity: string;
  newDocRenPriorActivity: string;

  newGenDocForm: UntypedFormGroup;
  newRenDocForm: UntypedFormGroup;
  contractorTypeTags: ItemTag[];

  registrationAutoVoidForm: UntypedFormGroup;
  renewalAutoVoidForm: UntypedFormGroup;
  registrationAutoVoidActivities: Activity<ActivityModel>[];
  renewalAutoVoidActivities: Activity<ActivityModel>[];
  registrationAutoVoidExample: string;
  renewalAutoVoidExample: string;
  registrationEnableAutoVoid: boolean;
  renewalEnableAutoVoid: boolean;

  newContractorRequirement: ContractorTypeRequirement;
  newRegistrationRequirement: ContractorTypeRequirement;
  saving = false;

  newRegOutputDoc: PrintTemplateActivity;
  newRenOutputDoc: PrintTemplateActivity;

  testRegisterLoading = false;
  testRenewalLoading = false;

  isCollapsed = {
    general: true,
    fees: false,
    emails: false,
    fields: false,
    documents: false
  };

  validity = {
    register: [],
    renewal: []
  };

  isDestroyed = false;

  get validationInfo() {
    const isRegisterError = this.validity.register.length > 0;
    const isRenewalError = this.validity.renewal.length > 0;
    return {
      register: {
        icon: isRegisterError ? 'error' : 'check_circle',
        class: isRegisterError ? 'btn-danger' : 'btn-primary',
        text: `${this.validity.register.length} problems in this workflow`
      },
      renewal: {
        icon: isRenewalError ? 'error' : 'check_circle',
        class: isRenewalError ? 'btn-danger' : 'btn-primary',
        text: `${this.validity.renewal.length} problems in this workflow`
      }
    };
  }

  private contractorTemplates: Workflow[];

  get registerTemplates(): Workflow[] {
    return this.contractorTemplates
      ? this.contractorTemplates.filter(
          w => w.workflowType === WorkflowType.ContractorRegistration
        )
      : null;
  }
  get renewTemplates(): Workflow[] {
    return this.contractorTemplates
      ? this.contractorTemplates.filter(
          w => w.workflowType === WorkflowType.ContractorRenewal
        )
      : null;
  }

  expirationOptions: any[];
  selectedExpirationOption: {
    name: string;
    label: string;
    expSettings?: ConditionTarget[];
  };
  termLengthMonths: number;
  customFormulaCriteria: ConditionTarget[] = [];
  isNewType = false;
  months: { key: string; value: number }[] = [];
  days: number[] = [];
  registerAutoReset = false;
  renewalAutoReset = false;
  registerFixedNumberPreview: string;
  renewalFixedNumberPreview: string;
  registerCalcNextDate = false;
  renewalCalcNextDate = false;
  registerNextResetDate: string;
  renewalNextResetDate: string;

  contractorRequirements: ContractorTypeRequirement[];
  registrationRequirements: ContractorTypeRequirement[];

  expirationCustomForumulaExcludedTemplateCodes = ['EXPIRATION_DATE'];

  constructor(
    public context: WorkflowContextService,
    private route: ActivatedRoute,
    private _contractorSvc: ContractorService,
    private _fb: UntypedFormBuilder,
    private toastr: ToastrService,
    public workflowSvc: WorkflowService,
    private _previousRouteSvc: PreviousRouteService,
    private _router: Router,
    private _ref: ChangeDetectorRef,
    private _validation: WorkflowValidationService,
    private modalService: NgbModal,
    private _systemSvc: SystemService
  ) {}

  ngOnDestroy(): void {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
    }

    this._ref.detach();
    this.isDestroyed = true;
  }

  ngOnInit() {
    this.newRegOutputDoc = <PrintTemplateActivity>(
      ActivityFactory.createActivity(
        new PrintTemplateActivity({ id: Utilities.generateId() })
      )
    );

    this.newRenOutputDoc = <PrintTemplateActivity>(
      ActivityFactory.createActivity(
        new PrintTemplateActivity({ id: Utilities.generateId() })
      )
    );

    this.months = range(1, 13, 1).map((m, idx) => {
      return {
        key: moment()
          .month(m - 1)
          .format('MMMM'),
        value: m
      };
    });
    this.days = range(1, 29).map((d, idx) => d);

    this.form = this._fb.group({
      name: this._fb.control('', Validators.required),
      description: this._fb.control('', Validators.nullValidator),
      visibleToPublic: this._fb.control('', Validators.nullValidator),
      registerWorkflowId: this._fb.control('', Validators.required),
      renewWorkflowId: this._fb.control('', Validators.required),
      customFormula: this._fb.control('', [Validators.nullValidator]),
      termLength: this._fb.control(
        '',
        Validators.compose([Validators.required, Validators.min(1)])
      ),
      registrationNumberPrefix: this._fb.control('', Validators.nullValidator),
      renewalNumberPrefix: this._fb.control('', Validators.nullValidator),
      registrationStartingNumber: this._fb.control(
        '',
        Validators.nullValidator
      ),
      registerTotalLength: this._fb.control('', Validators.nullValidator),
      registerAutoReset: this._fb.control('', Validators.nullValidator),
      registerResetMonth: this._fb.control('', Validators.required),
      registerResetDay: this._fb.control('', Validators.required),
      renewalTotalLength: this._fb.control('', Validators.nullValidator),
      renewalAutoReset: this._fb.control('', Validators.nullValidator),
      renewalResetMonth: this._fb.control('', Validators.required),
      renewalResetDay: this._fb.control('', Validators.required),
      renewalStartingNumber: this._fb.control('', Validators.nullValidator),
      renewalApplicationDescriptionTemplate: this._fb.control(
        '',
        Validators.nullValidator
      ),
      registrationApplicationDescriptionTemplate: this._fb.control(
        '',
        Validators.nullValidator
      ),
      registrationEnableAutoVoid: this._fb.control('', [Validators.nullValidator]),
      renewalEnableAutoVoid: this._fb.control('', [Validators.nullValidator])
    });

    this.newGenDocForm = this._fb.group({
      priorActivity: this._fb.control('', Validators.required),
      newDocName: this._fb.control(
        this.newRegOutputDoc.model.screenName,
        Validators.required
      )
    });
    this.newRenDocForm = this._fb.group({
      priorActivity: this._fb.control('', Validators.required),
      newDocName: this._fb.control(
        this.newRenOutputDoc.model.screenName,
        Validators.required
      )
    });

    this.form.controls['termLength'].setValidators(Validators.nullValidator);
    this.form.controls['termLength'].updateValueAndValidity();

    if (this.form.controls['registerTotalLength']) {
      this.form.controls['registerTotalLength'].valueChanges.subscribe(() => {
        this.calculateRegisterFixedNumberPreview();
      });
    }
    if (this.form.controls['registrationStartingNumber']) {
      this.form.controls['registrationStartingNumber'].valueChanges.subscribe(
        () => {
          this.calculateRegisterFixedNumberPreview();
        }
      );
    }

    if (this.form.controls['renewalTotalLength']) {
      this.form.controls['renewalTotalLength'].valueChanges.subscribe(() => {
        this.calculateRenewalFixedNumberPreview();
      });
    }
    if (this.form.controls['renewalStartingNumber']) {
      this.form.controls['renewalStartingNumber'].valueChanges.subscribe(() => {
        this.calculateRenewalFixedNumberPreview();
      });
    }

    if (this.context.client) {
      this.loadContractorType();
    } else {
      this.clientSubscription = this.context.client$.subscribe(() =>
        this.loadContractorType()
      );
    }
  }

  registrationToggleAutoVoid(e) {
    if (this.registrationEnableAutoVoid) {
      if (!this.contractorType.registerWorkflow.autoVoidSettings) {
        this.contractorType.registerWorkflow.autoVoidSettings = {
          goBeyondActivity: '',
          daysUntilVoid: 0
        };
      }
      this.registrationAutoVoidForm.enable();
    } else {
      this.contractorType.registerWorkflow.autoVoidSettings = null;
      this.registrationAutoVoidForm.disable();
    }
    this.calculateRegistrationAutoVoidExample();
  }

  renewalToggleAutoVoid(e) {
    if (this.renewalEnableAutoVoid) {
      if (!this.contractorType.renewWorkflow.autoVoidSettings) {
        this.contractorType.renewWorkflow.autoVoidSettings = {
          goBeyondActivity: '',
          daysUntilVoid: 0
        };
      }
      this.renewalAutoVoidForm.enable();
    } else {
      this.contractorType.renewWorkflow.autoVoidSettings = null;
      this.renewalAutoVoidForm.disable();
    }
    this.calculateRenewalAutoVoidExample();
  }

  loadRegisterWorkflowActivities() {
    this.workflowSvc
      .getWorkflowActivities(this.contractorType.registerWorkflow)
      .subscribe(activities => {
        if (activities) {
          this.registrationAutoVoidActivities = activities
            .filter(a => a.showOnScreen)
            .sort((a, b) => (a.level < b.level ? -1 : 1));
        }
      });
  }

  loadRenewalWorkflowActivities() {
    this.workflowSvc
      .getWorkflowActivities(this.contractorType.renewWorkflow)
      .subscribe(activities => {
        if (activities) {
          this.renewalAutoVoidActivities = activities
            .filter(a => a.showOnScreen)
            .sort((a, b) => (a.level < b.level ? -1 : 1));
        }
      });
  }

  calculateRegistrationAutoVoidExample() {
    setTimeout(() => {
      if (
        this.contractorType.registerWorkflow.autoVoidSettings &&
        this.contractorType.registerWorkflow.autoVoidSettings.goBeyondActivity &&
        this.contractorType.registerWorkflow.autoVoidSettings.daysUntilVoid &&
        this.registrationAutoVoidActivities
      ) {
        const startDate = moment();
        const targetDate = moment();
        targetDate.add(this.contractorType.registerWorkflow.autoVoidSettings.daysUntilVoid, 'days');
        const targetActivity = this.registrationAutoVoidActivities.find(
          a => a.id == this.contractorType.registerWorkflow.autoVoidSettings.goBeyondActivity
        );

        if (targetActivity) {
          this.registrationAutoVoidExample = `An Application that starts on ${startDate.format(
            'MM/DD/YYYY'
          )} and doesn't complete the '${
            targetActivity.model.screenName
          }' Activity before the end of the day on ${targetDate.format(
            'MM/DD/YYYY'
          )} will get automatically voided the night of ${targetDate.format(
            'MM/DD/YYYY'
          )}.`;
        } else {
          this.registrationAutoVoidExample = null;
        }
      } else {
        this.registrationAutoVoidExample = null;
      }
    }, 100);
  }

  calculateRenewalAutoVoidExample() {
    setTimeout(() => {
      if (
        this.contractorType.renewWorkflow.autoVoidSettings &&
        this.contractorType.renewWorkflow.autoVoidSettings.goBeyondActivity &&
        this.contractorType.renewWorkflow.autoVoidSettings.daysUntilVoid &&
        this.renewalAutoVoidActivities
      ) {
        const startDate = moment();
        const targetDate = moment();
        targetDate.add(this.contractorType.renewWorkflow.autoVoidSettings.daysUntilVoid, 'days');
        const targetActivity = this.renewalAutoVoidActivities.find(
          a => a.id == this.contractorType.renewWorkflow.autoVoidSettings.goBeyondActivity
        );

        if (targetActivity) {
          this.renewalAutoVoidExample = `An Application that starts on ${startDate.format(
            'MM/DD/YYYY'
          )} and doesn't complete the '${
            targetActivity.model.screenName
          }' Activity before the end of the day on ${targetDate.format(
            'MM/DD/YYYY'
          )} will get automatically voided the night of ${targetDate.format(
            'MM/DD/YYYY'
          )}.`;
        } else {
          this.renewalAutoVoidExample = null;
        }
      } else {
        this.renewalAutoVoidExample = null;
      }
    }, 100);
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onExpirationOptionChange() {
    if (this.selectedExpirationOption.name !== 'custom-months') {
      this.form.controls['termLength'].setValidators(Validators.nullValidator);
      this.form.controls['termLength'].updateValueAndValidity();
    } else {
      this.form.controls['termLength'].setValidators([
        Validators.required,
        Validators.min(1)
      ]);
    }
  }

  toggleRegisterAutoReset() {
    if (this.registerAutoReset) {
      this.form.controls['registerResetMonth'].enable();
      this.form.controls['registerResetDay'].enable();
    } else {
      if (this.contractorType && this.contractorType.registerWorkflow) {
        this.contractorType.registerWorkflow.resetClientAppNumberDay = null;
        this.contractorType.registerWorkflow.resetClientAppNumberMonth = null;
      }

      this.registerNextResetDate = null;
      this.form.controls['registerResetMonth'].disable();
      this.form.controls['registerResetDay'].disable();
    }
    this.form.controls['registerResetDay'].updateValueAndValidity();
    this.form.controls['registerResetMonth'].updateValueAndValidity();
    this.detectChanges();
  }

  toggleRenewalAutoReset() {
    if (this.renewalAutoReset) {
      this.form.controls['renewalResetMonth'].enable();
      this.form.controls['renewalResetDay'].enable();
    } else {
      if (this.contractorType && this.contractorType.renewWorkflow) {
        this.contractorType.renewWorkflow.resetClientAppNumberDay = null;
        this.contractorType.renewWorkflow.resetClientAppNumberMonth = null;
      }
      this.renewalNextResetDate = null;
      this.form.controls['renewalResetMonth'].disable();
      this.form.controls['renewalResetDay'].disable();
    }
    this.form.controls['renewalResetDay'].updateValueAndValidity();
    this.form.controls['renewalResetMonth'].updateValueAndValidity();
    this.detectChanges();
  }

  calculateRegisterFixedNumberPreview() {
    if (
      this.contractorType.registerWorkflow &&
      this.contractorType.registerWorkflow.clientApplicationNumber &&
      this.contractorType.registerWorkflow.fixedClientApplicationNumberLength
    ) {
      this.registerFixedNumberPreview = this.contractorType.registerWorkflow.clientApplicationNumber
        .toString()
        .padStart(
          this.contractorType.registerWorkflow
            .fixedClientApplicationNumberLength,
          '0'
        );
    } else if (
      this.contractorType &&
      this.contractorType.registerWorkflow &&
      this.contractorType.registerWorkflow.clientApplicationNumber
    ) {
      this.registerFixedNumberPreview = this.contractorType.registerWorkflow.clientApplicationNumber.toString();
    } else {
      this.registerFixedNumberPreview = '';
    }
    this.detectChanges();
  }

  calculateRenewalFixedNumberPreview() {
    if (
      this.contractorType.renewWorkflow &&
      this.contractorType.renewWorkflow.clientApplicationNumber &&
      this.contractorType.renewWorkflow.fixedClientApplicationNumberLength
    ) {
      this.renewalFixedNumberPreview = this.contractorType.renewWorkflow.clientApplicationNumber
        .toString()
        .padStart(
          this.contractorType.renewWorkflow.fixedClientApplicationNumberLength,
          '0'
        );
    } else if (
      this.contractorType &&
      this.contractorType.renewWorkflow &&
      this.contractorType.renewWorkflow.clientApplicationNumber
    ) {
      this.renewalFixedNumberPreview = this.contractorType.renewWorkflow.clientApplicationNumber.toString();
    } else {
      this.renewalFixedNumberPreview = '';
    }
    this.detectChanges();
  }

  calculateRegisterNextResetDate() {
    this.contractorType.registerWorkflow.resetClientAppNumberMonth = this.form.controls[
      'registerResetMonth'
    ].value;
    this.contractorType.registerWorkflow.resetClientAppNumberDay = this.form.controls[
      'registerResetDay'
    ].value;

    if (
      this.contractorType.registerWorkflow &&
      this.contractorType.registerWorkflow.resetClientAppNumberDay &&
      this.contractorType.registerWorkflow.resetClientAppNumberMonth &&
      this.registerCalcNextDate
    ) {
      this.workflowSvc
        .calculateClientAppNumberResetDate(
          this.contractorType.registerWorkflow.resetClientAppNumberMonth,
          this.contractorType.registerWorkflow.resetClientAppNumberDay
        )
        .subscribe(resetDate => {
          this.registerNextResetDate = resetDate;
          this.detectChanges();
        });
    }
  }

  calculateRenewalNextResetDate() {
    this.contractorType.renewWorkflow.resetClientAppNumberMonth = this.form.controls[
      'renewalResetMonth'
    ].value;
    this.contractorType.renewWorkflow.resetClientAppNumberDay = this.form.controls[
      'renewalResetDay'
    ].value;

    if (
      this.contractorType.renewWorkflow &&
      this.contractorType.renewWorkflow.resetClientAppNumberDay &&
      this.contractorType.renewWorkflow.resetClientAppNumberMonth &&
      this.renewalCalcNextDate
    ) {
      this.workflowSvc
        .calculateClientAppNumberResetDate(
          this.contractorType.renewWorkflow.resetClientAppNumberMonth,
          this.contractorType.renewWorkflow.resetClientAppNumberDay
        )
        .subscribe(resetDate => {
          this.renewalNextResetDate = resetDate;
          this.detectChanges();
        });
    }
  }

  private getWorkflows() {
    this._contractorSvc
      .getGlobalContractorWorkflows()
      .subscribe(globalWorkflows => {
        this.contractorTemplates = globalWorkflows;

        if (!this.contractorTypeId) {
          this.contractorType.registerWorkflowId =
            this.registerTemplates &&
            this.registerTemplates[0] &&
            this.registerTemplates[0].id;

          this.contractorType.renewWorkflowId =
            this.renewTemplates &&
            this.renewTemplates[0] &&
            this.renewTemplates[0].id;
        }
      });
  }

  removeDataEntity(de: DataEntity, list: any, key: any, workflowId: string) {
    const formActivity: FormActivity = <FormActivity>de.parentActivity;

    if (formActivity) {
      formActivity.model.formLayoutModel.removeEntity(
        new DataEntityLayoutModel({ entity: de })
      );
      for (const k in list) {
        if (list[k].templateCode === key) {
          delete list[k];
          break;
        }
      }
      this.workflowSvc.deleteDataEntity(workflowId, key).subscribe();
    }
  }

  addNewContractorRequirement() {
    this.newContractorRequirement = new ContractorTypeRequirement();
    this.newContractorRequirement.id = Utilities.generateId();

    this._ref.detectChanges();
  }

  addNewRegistrationRequirement() {
    this.newRegistrationRequirement = new ContractorTypeRequirement();
    this.newRegistrationRequirement.id = Utilities.generateId();

    this._ref.detectChanges();
  }

  newRequirementApplied(requirement: ContractorTypeRequirement) {
    this.contractorType.requirements.push(requirement);
    this.newContractorRequirement = null;
    this.newRegistrationRequirement = null;
    this.save();
  }

  newContractorRequirementCancelled() {
    this.newContractorRequirement = null;
    this._ref.detectChanges();
  }

  newRegistrationRequirementCancelled() {
    this.newRegistrationRequirement = null;
    this._ref.detectChanges();
  }

  removeRequirement(requirement: ContractorTypeRequirement) {
    const index = this.contractorType.requirements.indexOf(requirement);
    this.contractorType.requirements.splice(index, 1);

    this.save();
  }

  detectChanges() {
    if (this._ref && !this.isDestroyed) {
      this._ref.detectChanges();
    }
    if (this.form) {
      this.form.updateValueAndValidity();
    }
  }

  updateCriteria(
    criteria: ConditionTarget[],
    workflow: Workflow,
    controlName: string
  ) {
    workflow.numberPrefixCriteria = criteria;
    let hasValue = false;

    if (criteria && criteria.length > 0) {
      criteria.forEach((c, index) => {
        if ((c.value || '') !== '') {
          hasValue = true;
          return false;
        }
      });
    }
    this.form.controls[controlName].setValue(hasValue ? 'done' : '');
    this.detectChanges();
  }

  updateExpirationCriteria(criteria: ConditionTarget[]) {
    this.customFormulaCriteria = criteria;
    let hasValue = false;

    if (criteria && criteria.length > 0) {
      criteria.forEach((c, index) => {
        if ((c.value || '') !== '') {
          hasValue = true;
          return false;
        }
      });
    }
    this.form.controls['customFormula'].setValue(hasValue ? 'done' : '');
    this.detectChanges();
  }

  // Use => in order to force `this` into being the FeeDataEntityEditorComponent
  getThenLabel = (clause: ConditionTarget): string => {
    if (clause) {
      return clause.value;
    } else {
      return 'needs refactored';
    }
  }

  save() {
    if (this.form.valid) {
      this.saving = true;

      switch (this.selectedExpirationOption.name) {
        case 'infinite':
          this.selectedExpirationOption.expSettings = null;
          break;
        case 'end-of-issue-year':
          this.selectedExpirationOption.expSettings = [
            new ConditionTarget({
              id: '196B539E-B330-4E4B-B577-A850D73B13AA', // hard-coded for ease of selected option loading
              value: 'DATE(YEAR(${EFFECTIVE_DATE}),12,31)'
            })
          ];
          break;
        case 'custom-months':
          this.selectedExpirationOption.expSettings = [
            new ConditionTarget({
              id: '475A27B0-1AD9-4840-8E63-BB290D979F6C', // hard-coded for ease of selected option loading
              value: `EDATE(\${EFFECTIVE_DATE}, ${this.termLengthMonths})`
            })
          ];
          break;
        case 'custom-formula':
          this.selectedExpirationOption.expSettings = this.customFormulaCriteria;
          break;
        default:
          this.selectedExpirationOption.expSettings = null;
          break;
      }

      this.contractorType.expirationFormulaCriteria = this.selectedExpirationOption.expSettings;

      this._contractorSvc.saveContractorType(this.contractorType).subscribe(
        type => {
          this.toastr.success('Saved!');
          this.saving = false;

          if (
            !this.contractorType.id ||
            this.contractorType.id === Utilities.EMPTY_GUID
          ) {
            // navigate to the edit type with the current load
            this._router.navigate([
              'admin',
              'jurisdiction',
              this.context.client.id,
              'contractors',
              'types',
              'edit',
              type.id
            ]);
          } else {
            this.contractorType = type;

            this.sortRequirements();

            this.runValidation();
          }
        },
        err => {
          this.saving = false;
          throw err;
        }
      );
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  getValues(dict: any): any {
    const result = Object.keys(dict)
      .map(k => dict[k])
      .filter(v => isObject(v));
    return result;
  }

  buildEntities(): Observable<DataEntity[]> {
    return this.workflowSvc.getDataEntities(
      this.contractorType.registerWorkflow,
      null,
      null,
      [
        WorkflowService.ACTIVITIES.ContractorInfo,
        WorkflowService.ACTIVITIES.ContractorRegistrationInfo
      ]
    );
  }

  changeSettingsTab(e) {
    this.detectChanges();
  }

  loadContractorType() {
    this.getWorkflows();
    this.toggleRegisterAutoReset();
    this.toggleRenewalAutoReset();
    this.route.params.subscribe(params => {
      this.contractorTypeId = params['contractorTypeId'];

      if (this.contractorTypeId == null) {
        this.contractorType = {
          id: Utilities.EMPTY_GUID,
          clientID: this.context.client.id,
          client: this.context.client,
          isPublished: false,
          visibleToPublic: true,
          expirationFormulaCriteria: null
        } as ContractorType;

        this.isNewType = true;
        this.loadExpirationOptions();
      } else {
        this.registrationAutoVoidForm = this._fb.group({
          goBeyondActivity: this._fb.control('', [Validators.required]),
          daysUntilVoid: this._fb.control('', [Validators.required, Validators.min(1)])
        });

        this.renewalAutoVoidForm = this._fb.group({
          goBeyondActivity: this._fb.control('', [Validators.required]),
          daysUntilVoid: this._fb.control('', [Validators.required, Validators.min(1)])
        });

        this.form.addControl('registrationAutoVoid', this.registrationAutoVoidForm);
        this.form.addControl('renewalAutoVoid', this.renewalAutoVoidForm);

        this.form.controls['registerWorkflowId'].disable();
        this.form.controls['renewWorkflowId'].disable();

        this._contractorSvc
          .getContractorType(this.contractorTypeId, true)
          .subscribe(type => {
            this.contractorTypeTags = type.contractorTypeTags.map(
              ct =>
                <ItemTag>{
                  itemId: ct.contractorTypeId,
                  tag: ct.tag,
                  tagId: ct.tagId
                }
            );

            this.contractorType = type;

            this.sortRequirements();

            this.buildEntities().subscribe(entities => {
              this.contractorEntities = entities.filter(
                e =>
                  e.parent.activityType ===
                  WorkflowService.ACTIVITIES.ContractorInfo
              );

              this.availableContractorEntities = this.contractorEntities.filter(
                ce =>
                  !this.contractorRequirements.some(
                    cr => cr.templateCode === ce.templateCode
                  )
              );

              this.registrationEntities = entities.filter(
                e =>
                  e.parent.activityType ===
                  WorkflowService.ACTIVITIES.ContractorRegistrationInfo
              );

              this.availableRegistrationEntities = this.registrationEntities.filter(
                re =>
                  !this.registrationRequirements.some(
                    rr => rr.templateCode === re.templateCode
                  )
              );

              this.detectChanges();
            });

            this.runValidation();

            this.loadExpirationOptions();

            if (type) {
              this.registerAutoReset =
                type.registerWorkflow.resetClientAppNumberMonth != null;
              this.renewalAutoReset =
                type.renewWorkflow.resetClientAppNumberMonth != null;

              if (type.registerWorkflow) {
                this.form.controls['registerResetMonth'].setValue(
                  type.registerWorkflow.resetClientAppNumberMonth
                );
                this.form.controls['registerResetDay'].setValue(
                  type.registerWorkflow.resetClientAppNumberDay
                );
              }

              if (type.renewWorkflow) {
                this.form.controls['renewalResetMonth'].setValue(
                  type.renewWorkflow.resetClientAppNumberMonth
                );
                this.form.controls['renewalResetDay'].setValue(
                  type.renewWorkflow.resetClientAppNumberDay
                );
              }

              this.registrationEnableAutoVoid = this.contractorType.registerWorkflow.autoVoidSettings != null;
              this.renewalEnableAutoVoid = this.contractorType.renewWorkflow.autoVoidSettings != null;

              this.registrationToggleAutoVoid(null);
              this.renewalToggleAutoVoid(null);
              this.toggleRegisterAutoReset();
              this.toggleRenewalAutoReset();

              this.loadRegisterWorkflowActivities();
              this.loadRenewalWorkflowActivities();
              this.calculateRegistrationAutoVoidExample();
              this.calculateRenewalAutoVoidExample();
              this.detectChanges();
            }

            // this is here so we don't recalculate the next date right away.
            setTimeout(() => {
              this.registerCalcNextDate = true;
              this.renewalCalcNextDate = true;
              this.calculateRegisterNextResetDate();
              this.calculateRenewalNextResetDate();

              this.form.controls['registrationAutoVoid'].valueChanges.subscribe(changes => {
                this.calculateRegistrationAutoVoidExample();
              });

              this.form.controls['renewalAutoVoid'].valueChanges.subscribe(changes => {
                this.calculateRenewalAutoVoidExample();
              });

            }, 200);
          });
      }
    });
  }

  sortRequirements() {
    this.contractorRequirements = this.contractorType.requirements.filter(
      req => req.isContractorRequirement
    );
    this.registrationRequirements = this.contractorType.requirements.filter(
      req => !req.isContractorRequirement
    );
  }

  startTestRenewal() {
    this.testRenewalLoading = true;
    this._contractorSvc
      .startRegistrationRenewal(null, this.contractorType.id, true)
      .subscribe(
        response => {
          this._router.navigate([
            '/application',
            'workflow-application',
            response.applicationId
          ]);
          this.testRenewalLoading = false;
        },
        e => {
          this.testRenewalLoading = false;
          throw e;
        }
      );
  }

  canRemoveDocument(activity: Activity<ActivityModel>): boolean {
    const predecessors = activity.previousActivityIds;
    const successors = activity.routing;

    return !(predecessors.length > 1 && successors.length > 1);
  }

  startTestRegistration() {
    this.testRegisterLoading = true;
    this.workflowSvc
      .startApplication(this.contractorType.registerWorkflowId, true)
      .subscribe(
        response => {
          this._router.navigate([
            '/application',
            'workflow-application',
            response.applicationId
          ]);
          this.testRegisterLoading = false;
        },
        e => {
          this.testRegisterLoading = false;
          throw e;
        }
      );
  }

  addGeneratedDocument() {
    this.workflowSvc
      .addActivityToWorkflow(
        this.contractorType.registerWorkflowId,
        this.newDocPriorActivity,
        this.newRegOutputDoc
      )
      .subscribe((graph: WorkflowGraph) => {
        this.contractorType.registerWorkflow.version.graph = graph;
        this.contractorType.registerPrintTemplateActivities[
          this.newRegOutputDoc.id
        ] = this.newRegOutputDoc;
      });
  }

  addGeneratedRenewalDocument() {
    this.workflowSvc
      .addActivityToWorkflow(
        this.contractorType.renewWorkflowId,
        this.newDocRenPriorActivity,
        this.newRenOutputDoc
      )
      .subscribe((graph: WorkflowGraph) => {
        this.contractorType.renewWorkflow.version.graph = graph;
        this.contractorType.renewPrintTemplateActivities[
          this.newRenOutputDoc.id
        ] = this.newRenOutputDoc;
      });
    // .subscribe((activity: PrintTemplateActivity) => {
    //   this.contractorType.renewPrintTemplateActivities[
    //     activity.id
    //   ] = activity;
    //   this.contractorType.renewWorkflow.version.graph = new WorkflowGraph(
    //     this.contractorType.renewWorkflow.version.graph
    //   );
    //   this.contractorType.renewWorkflow.version.graph.addActivity(
    //     activity,
    //     ApplicationStatus.NotStarted
    //   );
    // });
  }

  cleanupRenewActivity(activityId: string) {
    delete this.contractorType.renewActivities[activityId];
  }

  cleanupRegisterationActivity(activityId: string) {
    delete this.contractorType.registerActivities[activityId];
  }

  removeActivity(
    workflow: Workflow,
    activity: Activity<ActivityModel>,
    activityList: { [key: string]: Activity<ActivityModel> }
  ) {
    this.workflowSvc
      .removeActivityFromWorkflow(workflow.id, activity.id)
      .subscribe((graph: WorkflowGraph) => {
        if (activityList) {
          delete activityList[activity.id];
          workflow.version.graph = graph;
        }

        if (this.contractorType.registerWorkflowId === workflow.id) {
          this.cleanupRegisterationActivity(activity.id);
        } else {
          this.cleanupRenewActivity(activity.id);
        }
      });
  }
  tagAdded(tag: Tag) {
    const addTag = () => {
      this.contractorTypeTags.push(<ItemTag>{
        itemId: this.contractorTypeId,
        tagId: tag.id,
        tag
      });
      this.contractorType.contractorTypeTags.push(<ContractorTypeTag>{
        contractorTypeId: this.contractorTypeId,
        tagId: tag.id,
        tag: tag
      });
    };

    if (!this.contractorTypeId) {
      addTag();
    } else {
      this._systemSvc
        .addContractorTypeTag(<ContractorTypeTag>{
          contractorTypeId: this.contractorTypeId,
          tagId: tag.id
        })
        .subscribe(result => {
          addTag();
        });
    }
  }

  tagRemoved(tag: Tag) {
    const removeTag = () => {
      const idx = this.contractorTypeTags.findIndex(ct => ct.tagId === tag.id);
      this.contractorTypeTags.splice(idx, 1);
      this.contractorType.contractorTypeTags.splice(idx, 1);
    };

    if (this.contractorTypeId) {
      this._systemSvc
        .removeContractorTypeTag(<ContractorTypeTag>{
          contractorTypeId: this.contractorTypeId,
          tagId: tag.id
        })
        .subscribe(result => {
          removeTag();
        });
    } else {
      removeTag();
    }
  }

  validateRegisterWorkflow() {
    this.openValidationInfo(this.contractorType.registerWorkflowId);
  }

  validateRenewalWorkflow() {
    this.openValidationInfo(this.contractorType.renewWorkflowId);
  }

  async validateWorkflow(workflowId: string) {
    return this._validation.validate(workflowId);
  }

  async openValidationInfo(workflowId: string) {
    const validity = await this.validateWorkflow(workflowId);
    const modalRef = this.modalService.open(WorkflowValidityModalComponent, {
      ariaLabelledBy: 'modal-validity-title',
      size: 'lg'
    });
    modalRef.componentInstance.validity = validity;
    modalRef.componentInstance.workflowId = workflowId;
  }

  async runValidation() {
    if (!this.contractorTypeId) {
      return;
    }
    const validation = await Promise.all([
      this.validateWorkflow(this.contractorType.registerWorkflowId),
      this.validateWorkflow(this.contractorType.renewWorkflowId)
    ]);

    this.validity.register = validation[0];
    this.validity.renewal = validation[1];
  }

  loadExpirationOptions() {
    const defaultOption = {
      name: 'infinite',
      label: 'Does not expire (infinite)'
    };
    const endOfYearOption = {
      name: 'end-of-issue-year',
      label: 'Expires at end of issued year'
    };
    const customMonthsOption = {
      name: 'custom-months',
      label: 'Set a term length in months from issue date'
    };
    const customFormulaOption = {
      name: 'custom-formula',
      label: 'Calculate expiration date with custom formula'
    };

    this.customFormulaCriteria = null;

    if (this.contractorTypeId !== null) {
      if (this.contractorType.expirationFormulaCriteria == null) {
        this.selectedExpirationOption = defaultOption;
      } else {
        if (
          this.contractorType.expirationFormulaCriteria.filter(
            efc => efc.id === '196B539E-B330-4E4B-B577-A850D73B13AA'
          ).length > 0
        ) {
          this.selectedExpirationOption = endOfYearOption;
        } else if (
          this.contractorType.expirationFormulaCriteria.filter(
            efc => efc.id === '475A27B0-1AD9-4840-8E63-BB290D979F6C'
          ).length > 0
        ) {
          this.selectedExpirationOption = customMonthsOption;
          const tls = this.contractorType.expirationFormulaCriteria[0].value
            .match(/\d/g)
            .join('');
          this.termLengthMonths = parseInt(tls, 10);
          this.form.controls['termLength'].setValidators([
            Validators.required,
            Validators.min(1)
          ]);
        } else {
          this.selectedExpirationOption = customFormulaOption;
          this.customFormulaCriteria = this.contractorType.expirationFormulaCriteria;
        }
      }
    }

    this.expirationOptions = [
      defaultOption,
      endOfYearOption,
      customMonthsOption
    ];
    if (!this.isNewType) {
      this.expirationOptions.push(customFormulaOption);
    }
  }
}
