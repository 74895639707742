<div class="row">
  <div class="col">
    <button
      type="button"
      class="btn btn-raised btn-success float-right"
      (click)="createPaymentAccount()"
    >
      New Payment Account
    </button>
  </div>
</div>

<wm-payment-account-list
  [paymentAccounts]="accounts"
  [allowSelect]="true"
  [showRemove]="true"
  (removed)="removed($event)"
></wm-payment-account-list>
