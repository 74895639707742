import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormControl
} from '@angular/forms';
import { Observable, Subscription, Subject, Subscriber } from 'rxjs';
import { Client } from 'src/app/models';
import { ValidateDomainResponse } from 'src/app/models/validate-domain-response';
import { ClientService, WorkflowContextService } from 'src/app/services';

@Component({
  selector: 'wm-client-email-settings',
  templateUrl: './client-email-settings.component.html',
  styleUrl: './client-email-settings.component.css'
})
export class ClientEmailSettingsComponent implements OnInit {
  form: UntypedFormGroup;
  client: Client;
  customFromDomainResult: ValidateDomainResponse;
  clientSub: Subscription;
  isCustomFromTestingEnabled: boolean;

  constructor(
    private _context: WorkflowContextService,
    private _clientSvc: ClientService
  ) {}

  ngOnInit(): void {
    let clientObs = null;

    if (this._context.client) {
      clientObs = this._clientSvc.getClient(this._context.client.id);
    } else {
      clientObs = this._context.client$;
    }

    this.clientSub = clientObs.subscribe(c => {
      this.client = c;
      this.isDomainTestValidationEnabled();
    });

    this.form = new UntypedFormGroup({
      customFromDomain: new UntypedFormControl('', Validators.required)
    });
  }

  save() {
    return this._clientSvc.saveEmailSettings({
      clientId: this.client.id,
      customFromDomain: this.client.customFromDomain
    });
  }

  enableDomainForTesting() {
    this._clientSvc
      .enableTestModeForCustomFromDomain(this.client.id)
      .subscribe(() => {
        this.isCustomFromTestingEnabled = true;
        this.customFromDomainResult = null;
      });
  }

  disableDomainForTesting() {
    this._clientSvc
      .disableTestModeForCustomFromDomain(this.client.id)
      .subscribe(() => {
        this.isCustomFromTestingEnabled = false;
        this.customFromDomainResult = null;
      });
  }

  isDomainTestValidationEnabled() {
    this._clientSvc
      .isDomainTestValidationEnabled(this.client.id)
      .subscribe(result => {
        this.isCustomFromTestingEnabled = result;
      });
  }

  validateCustomFromDomain() {
    this.save().subscribe(() => {
      this._clientSvc
        .validateCustomFromDomain(this.client.id)
        .subscribe(result => {
          this.customFromDomainResult = result;
        });
    });
  }
}
