import { Role } from './role';
import { MapService } from '.';
import { ContractorType } from './contractor-type';
import { ExternalDataConnection } from './external-data-connection';

export class Client {
  id: string;
  name: string;
  state: string;
  fullState: string;
  adminRole: Partial<Role>;
  parcelDataSource: string;
  visibleToPublic: boolean;
  logoURL: string;
  headerLogoURL: string;
  isEnabled: boolean;
  mapServices2: MapService[];
  mapServices: MapService[];
  externalDataConnections?: ExternalDataConnection[];
  adminUserId?: string;
  timeZoneId: string;
  timeZoneCode: string;
  timeZoneOffset: number;
  adminRoleHasViewAgendas: boolean;
  adminRoleID: string;
  hasPreviousSystemData: boolean;
  customFromDomain: string;
  isFromEmailAddressConfigured: boolean;

  contractorTypes: ContractorType[];

  constructor(options?: Partial<Client>) {
    // this.adminRole = new Role();

    if (options) {
      Object.assign(this, options);
    }
  }
}
