<app-loader *ngIf="!feeRecipients"></app-loader>

<ng-container *ngIf="feeRecipients">
  <div class="row ml-2 mb-5" *ngIf="feeRecipients.length === 0">
    <div class="flex-col pt-2">
      <i>No Fee Recipients to display</i>
    </div>
    <div class="col" *ngIf="canGenerateFeeRecipients">
      <button 
        class="btn btn-raised btn-primary" 
        (click)="generateRecipients()"
        [disabled]="waitingOnReport"
      >
        {{ waitingOnReport ? 'Searching Workflows...' : 'Generate Fee Recipients' }}
      </button>
    </div>
  </div>
  
  <table class="table" *ngIf="feeRecipients.length > 0">
      <tbody>
          <tr *ngFor="let fr of feeRecipients">
              <ng-container *ngIf="isEditing(fr.id)">
                <td>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="editingFeeRecipient.value"
                  />
                </td>
                <td>
                  <button class="btn btn-primary btn-sm" (click)="saveFeeRecipient(fr.id)">
                    Save
                  </button>
                </td>
                <td>
                  <button class="btn btn-primary btn-sm" (click)="cancelEditing()">
                    Cancel
                  </button>
                </td>
              </ng-container>
              <ng-container *ngIf="!isEditing(fr.id)">
                <th scope="row">
                    {{ fr.value }}
                </th>
                <td>
                    <button class="btn btn-primary btn-sm" (click)="editFeeRecipient(fr)">
                      Edit
                    </button>
                </td>
                <td>
                  <button class="btn btn-primary btn-sm" (click)="removeFeeRecipient(fr.id)">
                    Delete
                  </button>
                </td>
              </ng-container>
          </tr>
      </tbody>
  </table>
  
  <div class="row mt-4">
    <div class="col">
        <button
        class="btn btn-raised btn-success"
        (click)="addFeeRecipient()"
        >
        Add Fee Recipient
        </button>
    </div>
  </div>
</ng-container>

<wm-modal-confirm
  #deleteFeeRecipient
  [showButtonText]="true"
  [showButton]="false"
  [buttonText]="''"
  commandText="Yes"
  title="Delete Fee Recipient"
  (accepted)="removeFeeRecipient(deletingFeeRecipientId, true)"
  btnColor="success"
  class="mx-2"
  [closeOnAccept]="false"
>
  Are you sure you want to delete this Fee Recipient?
</wm-modal-confirm>

<wm-modal-confirm
  #generateFeeRecipients
  [showButtonText]="true"
  [showButton]="false"
  [buttonText]="''"
  commandText="Yes"
  title="Generate Fee Recipients"
  (accepted)="generateRecipients(true)"
  btnColor="success"
  class="mx-2"
  [closeOnAccept]="false"
>
  <ng-container *ngIf="feeRecipientGenerationImpactReport && feeRecipientGenerationImpactReport.impactedWorkflows">
    Fee Recipients were found on Fee Data Entities in the following Workflow Builders:

    <table class="table mt-3 mb-3">
      <tr>
        <td><b>Workflow</b></td>
        <td><b>Currently has unpublished changes</b></td>
      </tr>
      <tr *ngFor="let wi of feeRecipientGenerationImpactReport.impactedWorkflows">
        <td>{{ wi.workflowName }}</td>
        <td>
          <span *ngIf="wi.lastPendingChangesMade && wi.lastPendingChangesMade !== ''" class="text-warning">
            Yes ({{ wi.lastPendingChangesMade }})
          </span>
          <span *ngIf="!wi.lastPendingChangesMade || wi.lastPendingChangesMade === ''">
            No
          </span> 
        </td>
      </tr>
    </table>

    <br/>

    <p>
      Generating Fee Recipients from these workflows will automatically associate fee recipients being used in Fee Data Entities with this list.  The Workflow Builder updates will not be published, instead the workflow will show Changes Available if there were not already unpublished changes made to the workflow.
    </p>
    <p class="text-warning">
      <b>
        Before proceeding, it is recommended that the above list of workflows be copy/pasted and saved for reference for when these workflow changes will be published.
      </b>
    </p>
    <p>
      Do you want to continue and generate fee recipients from those found in these workflows?
    </p>
  </ng-container>

  
</wm-modal-confirm>

<wm-modal-confirm
  #noRecipientsFound
  [showButtonText]="true"
  [showButton]="false"
  [buttonText]="''"
  commandText="Ok"
  title="Fee Recipients Not Found"
  btnColor="success"
  class="mx-2"
  [closeOnAccept]="true"
>
  No Fee Recipients were found on Fee Data Entities in any Workflow designs.  You may create new Fee Recipients here on this page, or you can create them manually in workflow designs.
</wm-modal-confirm>