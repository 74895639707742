import { Component, OnInit, ViewChild } from '@angular/core';
import { PaymentAccountEditorComponent } from '../payment-account-editor/payment-account-editor.component';
import { FeeRecipientsEditorComponent } from '../fee-recipients-editor/fee-recipients-editor.component';
import { SecurityService } from 'src/app/services';
import { Actions } from 'src/app/models';

@Component({
  selector: 'wm-payment-settings',
  templateUrl: './payment-settings.component.html',
  styleUrl: './payment-settings.component.css'
})
export class PaymentSettingsComponent implements OnInit {
  @ViewChild('paymentAccounts', { static: false })
  paymentAccounts: PaymentAccountEditorComponent;
  @ViewChild('feeRecipients', { static: false })
  feeRecipients: FeeRecipientsEditorComponent;

  canManagePaymentAccounts = false;
  canManageFeeRecipients = false;

  constructor(private _securitySvc: SecurityService) {}

  ngOnInit() {
    this._securitySvc
      .isLoginEntitled(Actions.PAYMENT_PROCESSOR_ACCOUNT_MANAGE)
      .subscribe(r => {
        this.canManagePaymentAccounts = r;
      });

    this._securitySvc
      .isLoginEntitled(Actions.MANAGE_FEE_RECIPIENTS)
      .subscribe(r => {
        this.canManageFeeRecipients = r;
      });
  }
}
