import { TimeZoneInfo } from './../../../../models/time-zone-info';
import { Actions } from 'src/app/models';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Client,
  Role,
  ExternalDataConnection,
  MapService,
  ParcelDataConnection,
  PaymentAccount,
  User,
  ParcelDataSource
} from '../../../../models';
import {
  AbstractControl,
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import {
  WorkflowContextService,
  ClientService,
  SecurityService,
  DataService,
  ValidationService,
  SystemService,
  Utilities
} from '../../../../services';
import { map, flatMap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UploadResponse } from 'src/app/components/upload/upload.component';

@Component({
  selector: 'wm-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.css']
})
export class ClientDetailViewComponent implements OnInit, OnDestroy {
  client: Client;
  adminRoles: Role[];
  parcelDataSources: ParcelDataSource[];
  states: string[];
  clientId: string;
  adminRoleId: string;
  clientSubscribe: Subscription;
  clientName: AbstractControl;
  adminRole: AbstractControl;
  adminUser: AbstractControl;
  isNewClient: boolean;
  newMapService: MapService;
  saving = false;
  entitledToEnableDisable = false;
  timeZones: TimeZoneInfo[];

  selectedAdminUser: User;
  selectedParcelDataSource: string;

  form: UntypedFormGroup;
  lastUrl: string;
  constructor(
    public context: WorkflowContextService,
    private _clientSvc: ClientService,
    private _securitySvc: SecurityService,
    private _dataSvc: DataService,
    private _router: Router,
    private _systemSvc: SystemService,
    route: ActivatedRoute,
    fb: UntypedFormBuilder,
    private toastr: ToastrService
  ) {
    const url: Observable<string> = route.url.pipe(
      map(segments => segments.join(''))
    );
    url.subscribe(event => {
      this.lastUrl = event;
    });
    if (fb) {
      // #region setup validation
      this.form = fb.group({
        clientName: ['', Validators.required],
        adminRole: ['', Validators.required],
        parcelDataSource: [],
        sourceId: [],
        clientState: ['', Validators.required],
        visibleToPublic: [],
        adminUser: [],
        newMapService: new UntypedFormGroup({}),
        timeZone: ['', Validators.required]
      });

      this.clientName = this.form.controls['clientName'];
      this.adminRole = this.form.controls['adminRole'];
      this.adminUser = this.form.controls['adminUser'];
    }
    // #endregion
  }
  ngOnDestroy(): void {
    if (this.clientSubscribe) {
      this.clientSubscribe.unsubscribe();
    }
  }

  deleteLargeLogo(path: string) {
    this._dataSvc.deleteDocumentByPath(path).subscribe(() => {
      this.client.logoURL = '';
    });
  }
  deleteHeaderLogo(path: string) {
    this._dataSvc.deleteDocumentByPath(path).subscribe(() => {
      this.client.headerLogoURL = '';
    });
  }

  clearAdminUser() {
    this.selectedAdminUser = null;
  }
  associateAdminUser(adminUser: User) {
    this.selectedAdminUser = adminUser;
  }
  headerImageUploaded(file: UploadResponse) {
    this.client.headerLogoURL = file.path;
  }
  imageUploaded(file: UploadResponse) {
    this.client.logoURL = file.path;
  }

  loadingClient = false;

  loadClientDetails() {
    this.loadingClient = true;

    this._clientSvc
      .getClient(this.context.client.id)
      .pipe(
        map(client => {
          this.client = client;
          return this.client;
        })
      )
      .pipe(
        flatMap(client => {
          // return this._securitySvc.getRoles(client);
          return this._securitySvc.getRolesWithAllActions(
            client.id,
            Actions.CLIENT_ADMIN_ACTIONS
          );
        })
      )
      .pipe(
        map(roles => {
          this.adminRoles = roles;
          return this.client;
        })
      )
      .subscribe(() => {
        this.adminRoleId = this.client.adminRole
          ? this.client.adminRole.id
          : '';
        if (this.client.adminRole && this.adminRoles && this.adminRoles.length > 0) {
          this.adminRole.setValue(this.client.adminRole.id);
        }
        this.loadingClient = false;
      });

    this._dataSvc.getStates().subscribe(states => (this.states = states));
  }

  ngOnInit() {
    if (this.lastUrl === 'add') {
      this.client = <Client>{
        id: Utilities.EMPTY_GUID,
        mapServices: null,
        mapServices2: null,
        visibleToPublic: false,
        isEnabled: true
      };
      this.client.adminRole = <Role>{
        id: Utilities.EMPTY_GUID
      };
      this.newMapService = this._systemSvc.createMapService();
      this.newMapService.name = 'Parcel Data Source';
      this._dataSvc.getStates().subscribe(states => (this.states = states));
      this._systemSvc.getParcelDataSources().subscribe(result => {
        this.parcelDataSources = result;
      });
      this.isNewClient = true;
    } else {
      this.isNewClient = false;
      if (this.context.client) {
        this.loadClientDetails();
      } else {
        this.clientSubscribe = this.context.client$.subscribe(() => {
          this.loadClientDetails();
        });
      }
    }

    this._dataSvc.getTimeZones().subscribe(timeZones => {
      this.timeZones = timeZones;
    });

    this._securitySvc
      .isLoginEntitled(Actions.CLIENTS_MANAGE)
      .subscribe(entitled => {
        this.entitledToEnableDisable = entitled;
      });
  }

  selectAdminRole(e) {
    const roleId = e.srcElement.value;

    this.adminRoles.some((value: Role) => {
      if (value.id === roleId) {
        this.client.adminRole = value;
        return true;
      }
    });
  }
  save() {
    if (this.client.id === Utilities.EMPTY_GUID) {
      if ((this.newMapService.endpointUrl || '') !== '') {
        this.client.mapServices = [this.newMapService];
      }
      if (this.selectedAdminUser) {
        this.client.adminUserId = this.selectedAdminUser.id;
      }

      if (this.selectedParcelDataSource) {
        const parcelCx = new ParcelDataConnection();
        parcelCx.setSourceId(parseInt(this.selectedParcelDataSource, 10));
        this.client.externalDataConnections = [parcelCx];
      }
    }

    this.saving = true;
    this._clientSvc.saveClient(this.client).subscribe(
      res => {
        if (this.context.client) {
          this.context.client$.emit(this.client);
        } else {
          this.context.client = res;
        }
        this.saving = false;
        this.toastr.success('Saved!');

        // navigate back to the list when the save is finished
        this._router.navigate([
          '/admin/jurisdiction/',
          this.context.client.id,
          'settings',
          'general'
        ]);
      },
      err => {
        this.saving = false;
      }
    );
  }

  cancel() {
    this._router.navigate(['/admin/jurisdiction/', this.context.client.id]);
  }
  cancelDisable(e) {}
  disableClient(e) {
    this._dataSvc.disableClient(this.client).subscribe(ev => {
      this._clientSvc.getClient(this.client.id).subscribe(client => {
        this.toastr.success('Disabled!');
        this.client = client;
      });
    });
  }
  enableClient(e) {
    this._dataSvc.enableClient(this.client).subscribe(ev => {
      this._clientSvc.getClient(this.client.id).subscribe(client => {
        this.toastr.success('Enabled!');
        this.client = client;
      });
    });
  }
  canDisable() {
    if (this.client) {
      return this.client.id !== '' && this.client.isEnabled;
    } else {
      return false;
    }
  }
  canEnableDisable() {
    return this.entitledToEnableDisable;
  }
  canEnable() {
    if (this.client) {
      return this.client.id !== '' && !this.client.isEnabled;
    } else {
      return false;
    }
  }
}
