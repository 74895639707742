<app-loader *ngIf="loading"></app-loader>

<form [formGroup]="form" novalidate [hidden]="loading">
  <div *ngIf="activity">
    <div
      class="form-group"
      [class.has-error]="activityName.touched && !activityName.valid"
    >
      <label class="bmd-label-floating" for="activityName">
        Activity Name
      </label>
      <input
        type="text"
        class="form-control required"
        formControlName="activityName"
        maxlength="100"
        name="activityName"
        [(ngModel)]="activity.model.screenName"
      />
    </div>

    <div
      *ngIf="showResponsibleRole && roles"
      class="form-group"
      [class.has-error]="responsibleRole.touched && !responsibleRole.valid"
    >
      <label class="bmd-label-floating" for="responsibleRole">
        Responsible Role
      </label>
      <select
        formControlName="responsibleRole"
        name="responsibleRole"
        (change)="selectRole($event); refreshPermissions()"
        class="form-control required"
      >
        <option *ngFor="let role of roles" [value]="role.id.toUpperCase()">
          {{ role.name }}
        </option>
      </select>
    </div>

    <div class="form-group" *ngIf="showHelpText">
      <label class="bmd-label-floating" for="screenHelpText">
        Activity Help Text
      </label>
      <textarea
        class="form-control"
        rows="5"
        formControlName="screenHelpText"
        name="screenHelpText"
        [(ngModel)]="activity.model.screenHelpText"
      >
      </textarea>
    </div>

    <div class="switch" *ngIf="!roleIsApplicant">
      <label>
        <input
          type="checkbox"
          formControlName="visibleToPublic"
          [(ngModel)]="activity.model.visibleToPublic"
        />
        Visible to Applicant
      </label>
    </div>

    <ng-container *ngIf="activity.allowDisplayLabel">
      <div class="row mt-4">
        <div class="col-11">
          <h6>Display Label</h6>
        </div>
        <div class="col-1" style="padding-top: 1px; padding-left: 0">
          <wm-help-text
            placement="left"
            helpText="Build a formula to show dynamic text in the side navigation panel"
          ></wm-help-text>
        </div>
      </div>
      <div class="row mt-0 pt-0 mb-4">
        <div class="col-12">
          <div class="form-group mt-0 pt-0">
            <wm-data-entity-formula
              [required]="false"
              [criteria]="activity.displayLabel"
              [workflow]="workflow || _context.workflow"
              [thenLabelGetter]="getThenLabel"
              (criteriaChanged)="updateDisplayLabel($event)"
              formulaTitle=" "
              [onlyGlobalDataEntities]="false"
              placement="left"
            >
            </wm-data-entity-formula>
            <wm-control-message
              [control]="form.controls['displayLabel']"
            ></wm-control-message>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <template #activityTypeEditor></template>
</form>
