import { Component, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ModalConfirmComponent } from 'src/app/components/system/modal-confirm/modal-confirm.component';
import { Actions } from 'src/app/models';
import {
  FeeRecipient,
  FeeRecipientGenerationImpactReport,
  FeeRecipientGenerationRequest,
  WorkflowFeeRecipientItem
} from 'src/app/models/fee-recipient';
import {
  ClientService,
  SecurityService,
  Utilities,
  WorkflowContextService
} from 'src/app/services';

@Component({
  selector: 'wm-fee-recipients-editor',
  templateUrl: './fee-recipients-editor.component.html',
  styleUrl: './fee-recipients-editor.component.css'
})
export class FeeRecipientsEditorComponent {
  @ViewChild('deleteFeeRecipient') deleteModal: ModalConfirmComponent;
  @ViewChild('generateFeeRecipients') generateModal: ModalConfirmComponent;
  @ViewChild('noRecipientsFound') noRecipientsFoundModal: ModalConfirmComponent;

  feeRecipients: FeeRecipient[];
  clientSubscribe: Subscription;
  editingFeeRecipient: FeeRecipient = null;
  deletingFeeRecipientId: string;
  feeRecipientGenerationImpactReport: FeeRecipientGenerationImpactReport;
  waitingOnReport = false;
  canGenerateFeeRecipients = false;

  constructor(
    private _context: WorkflowContextService,
    private _securitySvc: SecurityService,
    private _clientSvc: ClientService,
    private _toastr: ToastrService
  ) {}

  ngOnInit() {
    if (this._context.client) {
      this.getFeeRecipients();
    } else {
      this.clientSubscribe = this._context.client$.subscribe(res => {
        this.getFeeRecipients();
      });
    }

    this._securitySvc
      .isLoginEntitled(Actions.GENERATE_FEE_RECIPIENTS)
      .subscribe(r => {
        this.canGenerateFeeRecipients = r;
      });
  }

  ngOnDestroy() {
    if (this.clientSubscribe) {
      this.clientSubscribe.unsubscribe();
    }
  }

  isEditing(feeRecipientId: string): boolean {
    return (
      this.editingFeeRecipient && this.editingFeeRecipient.id === feeRecipientId
    );
  }

  getFeeRecipients() {
    this._clientSvc
      .getFeeRecipients(this._context.client.id)
      .subscribe(feeRecipients => {
        this.feeRecipients = feeRecipients || [];
      });
  }

  addFeeRecipient() {
    let newRecipient = new FeeRecipient({
      id: Utilities.generateId(),
      value: '',
      clientId: this._context.client.id
    });

    this.feeRecipients.push(newRecipient);

    this.editingFeeRecipient = newRecipient;
  }

  removeFeeRecipient(feeRecipientId: string, confirmed = false) {
    if (confirmed) {
      this._clientSvc.deleteFeeRecipient(feeRecipientId).subscribe(success => {
        if (success) {
          this.feeRecipients = this.feeRecipients.filter(
            fr => fr.id !== feeRecipientId
          );

          this.deleteModal.cancel();
        } else {
          this._toastr.error(
            'Something went wrong when deleting the Fee Recipient. Please contact support if this error persists.',
            'Fee Recipient was not deleted.',
            { disableTimeOut: true }
          );
        }
      });
    } else {
      this.deletingFeeRecipientId = feeRecipientId;

      this.deleteModal.open();
    }
  }

  editFeeRecipient(feeRecipient: FeeRecipient) {
    this.editingFeeRecipient = feeRecipient;
  }

  saveFeeRecipient(feeRecipientId: string) {
    this._clientSvc
      .saveFeeRecipient(this.editingFeeRecipient)
      .subscribe(result => {
        let savedRecipient = this.feeRecipients.find(
          fr => fr.id === feeRecipientId
        );

        if (savedRecipient) {
          savedRecipient = result;
        }

        this.editingFeeRecipient = null;
      });
  }

  cancelEditing() {
    this.editingFeeRecipient = null;
  }

  generateRecipients(confirmed = false) {
    if (confirmed) {
      let workflowFeeRecipientItems: WorkflowFeeRecipientItem[] = [];

      for (let wi of this.feeRecipientGenerationImpactReport
        .impactedWorkflows) {
        for (let activityId in wi.feeEntityActivities) {
          const templateCodes = wi.feeEntityActivities[activityId];

          const wfris = templateCodes.map(
            tc =>
              new WorkflowFeeRecipientItem({
                draftVersionId: wi.draftVersionId,
                activityId: activityId,
                templateCode: tc
              })
          );

          workflowFeeRecipientItems.push(...wfris);
        }
      }

      const request = new FeeRecipientGenerationRequest({
        workflowFeeRecipientItems: workflowFeeRecipientItems
      });

      this._clientSvc.generateFeeRecipients(request).subscribe(response => {
        if (response.success) {
          this.feeRecipients = response.newFeeRecipients;

          this.generateModal.cancel();
        } else {
          this._toastr.error(
            'Something went wrong when generating Fee Recipients. Please contact support if this error persists.',
            'Could not generate Fee Recipients.',
            { disableTimeOut: true }
          );
        }
      });
    } else {
      this.feeRecipientGenerationImpactReport = null;
      this.waitingOnReport = true;

      this._clientSvc
        .getFeeRecipientGenerationImpactReport(this._context.client.id)
        .subscribe(report => {
          if (
            report &&
            report.impactedWorkflows &&
            report.impactedWorkflows.length > 0
          ) {
            this.feeRecipientGenerationImpactReport = report;

            this.generateModal.open();
          } else {
            this.noRecipientsFoundModal.open();
          }

          this.waitingOnReport = false;
        });
    }
  }
}
