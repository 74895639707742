import { Validators } from '@angular/forms';
import { ConditionTarget } from 'src/app/components/system/condition-builder/condition-builder.model';
import { CalculatedValueDataEntity } from './calculated-value-data-entity';
import { FeeRecipient } from '../fee-recipient';

export enum FeeDataEntityType {
  Simple,
  List,
  Advanced
}

export class FeeDataEntity extends CalculatedValueDataEntity {
  formulaCriteria: ConditionTarget[];
  feeRecipient: string;
  clientFeeRecipient: FeeRecipient;
  availableFeeRecipients: FeeRecipient[];
  type: FeeDataEntityType = FeeDataEntityType.Simple;
  listTemplateCode: string;
  paymentAccountId: string;
  canAdminEdit: boolean;
  isPaid: boolean;
  isEditable: boolean;
  isRequired = true;
  feeMaximum: number;
  feeMinimum: number;

  constructor(options?: Partial<FeeDataEntity>) {
    super(options);

    this.dataEntityTypeCode = 'fee';
    this.dataEntityTypeDescription = 'Use this entity type to calculate a fee.';
    this.dataEntityTypeName = 'Fee';

    if (options) {
      Object.assign(this, options);
    }
  }

  getValidators(): any[] {
    return [Validators.nullValidator];
  }
}
