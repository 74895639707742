import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wm-payment-account-editor',
  templateUrl: './payment-account-editor.component.html',
  styleUrls: ['./payment-account-editor.component.css']
})
export class PaymentAccountEditorComponent implements OnInit {
  constructor() {}

  ngOnInit() { }
}
