import { ConditionTarget } from './../../components/system/condition-builder/condition-builder.model';
import { EditorShape, WorkflowContextService } from '../../services';
import {
  Actions,
  Role,
  Securable,
  Permission,
  ApplicationProcessingStatus,
  FeedbackMessageType
} from '../../models';
import { DataEntity } from '../data-entities';

export interface OutputDefinition {
  id: string;
  name: string;
}

export interface InputDefinition {
  id: string;
  name: string;
}

export class ActivityModel {
  screenName: string;
  screenHelpText: string;
  visibleToPublic: boolean;
  responsibleRoleId: string;
  responsibleRole: Role;

  constructor(options?: Partial<ActivityModel>) {
    if (options) {
      Object.assign(this, options);
    }
  }

  getEntities(): DataEntity[] {
    return [];
  }
}

export enum ActivityStatus {
  NotStarted = 0,
  InProgress = 1,
  Completed = 2,
  Canceled = 3
}

export class Activity<T extends ActivityModel> implements Securable {
  id: string;
  type: string;
  name: string;
  description: string;
  shape?: EditorShape;
  isContainer: boolean;
  routing: RouteDefinition[] = [];
  inputs: InputDefinition[];
  outputs: OutputDefinition[];
  status: ActivityStatus;
  permissions: { [key: string]: { [key: string]: Permission } };
  registeredActions?: string[] = [];
  model: T;
  completedOn?: Date;
  completedOnString?: string;
  completedBy?: string;
  previousActivityId: string;
  previousActivityIds: string[];
  needsAction: boolean;
  applicationIteration: number;
  activityDataId: string;
  isResponsibleRoleRequired: boolean;
  availableActions: string[];
  isReadOnly: boolean;
  needsEditorDataLoaded: boolean;
  canGoPrevious: boolean;
  canGoNext: boolean;
  canEdit: boolean;
  canDiscardApplication: boolean;
  designerTooltip: string;
  feedbackMessage: string;
  feedbackMessageType: FeedbackMessageType;
  feedbackTitle: string;
  applicationProcessingStatus: ApplicationProcessingStatus;
  level: number;
  hasHistory: boolean;
  workflowId: string;
  showOnScreen: boolean;
  displayLabel: ConditionTarget[];
  summaryLabel: string;
  allowDisplayLabel: boolean;

  getDefaultActions(): string[] {
    return [];
  }

  public dereference(): void {}

  registerActions() {
    // override this function to register new actions for the activity
    this.availableActions = [
      ...this.getDefaultActions(),
      ...this.registeredActions
    ];
  }

  protected registerAction(id: string, name: string) {
    // register with the activity actions
    if (this.registeredActions.indexOf(id) === -1) {
      this.registeredActions.push(id);
    }

    // register with the security system
    Actions.registerAction({ id: id, name: name });
  }

  addEntity(entity: DataEntity) {}

  constructor(options?: Partial<Activity<ActivityModel>>) {
    this.routing = [];
    this.previousActivityIds = [];
    this.needsAction = true;

    if (options) {
      Object.assign(this, options);
    }
  }

  public displayOnScreen(): boolean {
    return false;
  }

  displayName(): string {
    return (this.model.screenName || '') !== ''
      ? this.model.screenName
      : this.name;
  }

  getValues(): { [key: string]: string } {
    return null;
  }

  getRoute(routeId: string): RouteDefinition {
    const route: RouteDefinition = this.routing.find(
      childRoute => childRoute.id === routeId
    );

    return route;
  }
}

export class ScreenActivity<T extends ActivityModel> extends Activity<T> {
  responsibleRole: Role;

  constructor(options?: Partial<ScreenActivity<T>>) {
    super(options);
  }

  public displayOnScreen(): boolean {
    return true;
  }

  displayName(): string {
    return this.model.screenName || this.name;
  }
}

export enum Operators {
  And,
  Or
}

export class WaitCriteria {
  criteria: string;
  operator: Operators;
}

export class RouteCriteria {
  targetId: string;
  criteria: ConditionTarget;

  constructor(options?: Partial<RouteCriteria>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class RouteDefinition {
  /**
   * Unique identifier of the route.
   */
  id: string;
  /**
   * The activity that this route came from.
   */
  sourceId: string;
  /**
   * The activity that this routes to.
   */
  targetId: string;
  /**
   * Conditions to evaluate wether this route should be taken.
   */
  criteria: RouteCriteria[] = [];

  shouldRoute: boolean;

  constructor(options?: Partial<RouteDefinition>) {
    if (options) {
      Object.assign(this, options);
    }
  }

  getToolTip(context: WorkflowContextService): string {
    // These are not on the object because it creates a circular reference when serializing the workflow
    const sourceActivity = context.searchWorkflowActivities(
      context.workflow,
      new Activity<ActivityModel>({ id: this.sourceId })
    );
    const targetActivity = context.searchWorkflowActivities(
      context.workflow,
      new Activity<ActivityModel>({ id: this.targetId })
    );

    if (!this.criteria || this.criteria.length === 0) {
      if (!sourceActivity || !targetActivity) {
        return '';
      }

      return `${sourceActivity.model.screenName} navigates to ${targetActivity.model.screenName}`;
    } else {
      let tip = '';

      this.criteria.forEach((criteria, index) => {
        if (index < this.criteria.length) {
          const criteriaTarget = context.searchWorkflowActivities(
            context.workflow,
            new Activity<ActivityModel>({ id: criteria.targetId })
          );
          if (criteriaTarget) {
            if (index === 0) {
              tip += `<div>if true then navigate to '${criteriaTarget.model.screenName}'</div>`;
            } else {
              tip += `<div>else if true then navigate to '${criteriaTarget.model.screenName}'</div>`;
            }
          }
        }
      });

      return tip;
    }
  }
}

export class ContainerActivity<T extends ActivityModel> extends Activity<T> {
  activities?: Activity<T>[];

  constructor(options?: Partial<ContainerActivity<T>>) {
    super(options);
  }
}
