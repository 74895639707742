<form [formGroup]="form" (submit)="save()" data-toggle="validator">
  <div class="row">
    <div class="col-md-6">
      <h3>General Settings</h3>
      <app-loader *ngIf="!client"></app-loader>
      <div *ngIf="client">
        <div
          class="form-group"
          [class.has-error]="clientName.touched && !clientName.valid"
        >
          <label for="clientName" class="bmd-label-floating">Name</label>
          <input
            type="text"
            class="form-control required"
            formControlName="clientName"
            [(ngModel)]="client.name"
            name="clientName"
          />
          <wm-control-message
            [control]="form.controls['clientName']"
          ></wm-control-message>
        </div>

        <div
          class="form-group"
          [class.has-error]="adminRole.touched && !adminRole.valid"
        >
          <label for="adminRole" class="bmd-label-floating">Admin Role</label>
          <app-loader *ngIf="loadingClient"></app-loader>
          <ng-container *ngIf="!loadingClient">
            <select
              *ngIf="adminRoles && adminRoles.length > 0"
              class="form-control required"
              (change)="selectAdminRole($event)"
              formControlName="adminRole"
              name="adminRole"
            >
              <option></option>
              <option *ngFor="let role of adminRoles" [value]="role.id">
                {{ role.name }}
              </option>
            </select>
            <input
              *ngIf="
                (!adminRoles || adminRoles.length === 0) &&
                client &&
                client.adminRole &&
                isNewClient
              "
              type="text"
              class="form-control required"
              [(ngModel)]="client.adminRole.name"
              formControlName="adminRole"
              name="adminRole"
            />
            <wm-control-message
              [control]="form.controls['adminRole']"
            ></wm-control-message>
            <div class="text-danger" *ngIf="adminRoles && adminRoles.length == 0 && !isNewClient">You don't have any roles that have enough permissions to be the Client Admin</div>
          </ng-container>
        </div>
        <ng-container *ngIf="isNewClient">
          <div class="form-group">
            <wm-associate-user
              label="Admin User"
              [form]="form.controls['adminUser']"
              (userAssociated)="associateAdminUser($event)"
              *ngIf="!selectedAdminUser"
            ></wm-associate-user>
            <div class="card" *ngIf="selectedAdminUser">
              <div class="card-header">
                Administrator Details<span class="float-right"
                  ><a style="cursor: pointer" (click)="clearAdminUser()"
                    >Clear User</a
                  ></span
                >
              </div>
              <div class="card-body">
                <wm-user-info [user]="selectedAdminUser"> </wm-user-info>
              </div>
            </div>
          </div>

          <app-loader *ngIf="!parcelDataSources"></app-loader>
          <div *ngIf="parcelDataSources" class="form-group">
            <label class="bmd-label-floating" for="sourceId"
              >Parcel Data Source</label
            >
            <select
              class="form-control required"
              [(ngModel)]="selectedParcelDataSource"
              formControlName="parcelDataSource"
              name="sourceId"
            >
              <option></option>
              <option *ngFor="let s of parcelDataSources" [value]="s.sourceId">
                {{ s.name }}
              </option>
            </select>
          </div>
          <div class="card">
            <div class="card-header">
              Setup Map Service Connection (Optional)
            </div>
            <div class="card-body">
              <wm-map-service-detail-view
                [showControls]="false"
                [mapService]="newMapService"
                [showTitle]="false"
                [isRequired]="false"
                [form]="form.controls['newMapService']"
              ></wm-map-service-detail-view>
            </div>
          </div>
        </ng-container>

        <div class="form-group">
          <label for="clientState" class="bmd-label-floating">State</label>
          <select
            class="form-control"
            [(ngModel)]="client.state"
            formControlName="clientState"
            name="clientState"
          >
            <option></option>
            <option *ngFor="let state of states" [value]="state">
              {{ state }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="timeZone" class="bmd-label-floating">Timezone</label>
          <select
            class="form-control"
            [(ngModel)]="client.timeZoneId"
            formControlName="timeZone"
            name="timeZone"
          >
            <option></option>
            <option *ngFor="let timeZone of timeZones" [value]="timeZone.Id">
              {{ timeZone.DisplayName }}
            </option>
          </select>
        </div>

        <div class="switch">
          <label>
            <input
              type="checkbox"
              class="float-right"
              [(ngModel)]="client.visibleToPublic"
              formControlName="visibleToPublic"
            />
            Visible to public?
          </label>
        </div>
      </div>
    </div>
    <div class="col-md-6" *ngIf="!isNewClient">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Large Logo</h5>
          <h6 class="card-subtitle mb-2">
            The large logo will be shown on the sidebar while a user is filling
            out an application.
          </h6>
          <h6 class="card-subtitle mb-2 text-muted">
            (recommended height: 150 pixels)
          </h6>
          <app-loader *ngIf="!client"></app-loader>
          <div *ngIf="client">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <wm-upload
                    id="logoUpload"
                    name="logoUpload"
                    validDocumentTypes="png,jpg,jpeg"
                    [allowMultipleFiles]="false"
                    [pathSegments]="[this.client.id]"
                    (fileUploaded)="imageUploaded($event)"
                    category="Client"
                  >
                  </wm-upload>
                </div>
              </div>
              <div class="col-6">
                <wm-modal-confirm
                  *ngIf="(client.logoURL || '') != ''"
                  [moduleId]="'delete_large_logo'"
                  [commandText]="'Delete Logo'"
                  [title]="'Delete  Logo'"
                  [showButtonText]="true"
                  (accepted)="deleteLargeLogo(client.logoURL)"
                  [enabled]="true"
                  btnIcon="delete"
                >
                  <p>Are you sure you want to delete the large logo?</p>
                  <p>
                    Deleting this logo will remove it from areas in the
                    application.
                  </p>
                </wm-modal-confirm>
              </div>
            </div>
            <img
              *ngIf="client.logoURL"
              [src]="client.logoURL"
              alt="jurisdiction logo"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <h5 class="card-title">Header Logo</h5>
          <h6 class="card-subtitle mb-2">
            The header logo will be shown on the top navigation bar on your
            jurisdiction home page.
          </h6>
          <h6 class="card-subtitle mb-2 text-muted">
            (recommended height: 27 pixels)
          </h6>
          <app-loader *ngIf="!client"></app-loader>
          <div *ngIf="client">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <wm-upload
                    id="headerLogoUpload"
                    name="headerLogoUpload"
                    validDocumentTypes="png,jpg,jpeg"
                    [allowMultipleFiles]="false"
                    [pathSegments]="[this.client.id, 'header']"
                    (fileUploaded)="headerImageUploaded($event)"
                    category="Client"
                  >
                  </wm-upload>
                </div>
              </div>
              <div class="col-6">
                <wm-modal-confirm
                  *ngIf="(client.headerLogoURL || '') != ''"
                  [moduleId]="'delete_header_logo'"
                  [commandText]="'Delete Header Logo'"
                  [title]="'Delete Header Logo'"
                  [showButtonText]="true"
                  (accepted)="deleteHeaderLogo(client.headerLogoURL)"
                  [enabled]="true"
                  btnIcon="delete"
                >
                  <p>Are you sure you want to delete the header logo?</p>
                  <p>
                    Deleting this logo will remove it from the header of the
                    system.
                  </p>
                </wm-modal-confirm>
              </div>
            </div>

            <img
              *ngIf="client.headerLogoURL"
              [src]="client.headerLogoURL"
              alt="jurisdiction header logo"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="client">
    <button
      type="submit"
      class="btn btn-raised btn-success mr-2"
      [disabled]="form.invalid || saving"
    >
      <span *ngIf="!saving">Save</span>

      <span *ngIf="saving">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Saving...
      </span>
    </button>

    <ng-container *ngIf="canEnableDisable()">
      <wm-modal-confirm
        *ngIf="canDisable() && !isNewClient"
        [commandText]="'Disable Jurisdiction'"
        [moduleId]="'disableClientModal'"
        [showButtonText]="true"
        [title]="'Disable Jurisdiction'"
        (canceled)="cancelDisable($event)"
        (accepted)="disableClient($event)"
        btnColor="danger"
        class="ml-2"
      >
        <h6>
          Are you sure you want to disable this jurisdiction ({{
            client.name
          }})?
        </h6>
        <p>Disabling the jurisdiction will do the following things:</p>
        <ul>
          <li>
            Remove the jurisdiction from the list of jurisdictions to complete
            applications.
          </li>
        </ul>
      </wm-modal-confirm>

      <wm-modal-confirm
        [commandText]="'Enable Jurisdiction'"
        [moduleId]="'enableClientModal'"
        [showButtonText]="true"
        [title]="'Enable Jurisdiction'"
        (accepted)="enableClient($event)"
        *ngIf="canEnable()"
        class="ml-2"
      >
        <h6>
          Are you sure you want to enable this jurisdiction ({{ client.name }})?
        </h6>
        <p>Enabling the application will do the following things:</p>
        <ul>
          <li>
            Add the jurisdiction to the list of jurisdictions to complete
            applications.
          </li>
        </ul>
      </wm-modal-confirm>
    </ng-container>
  </div>
</form>
