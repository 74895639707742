import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  Type,
  ComponentRef,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';
import {
  Activity,
  ScreenActivity,
  ActivityModel
} from '../../../../models/activities';
import { Actions, Role, Client } from '../../../../models';
import { AdvancedPermissionsEditorComponent } from '../../../security/advanced-permissions-editor/advanced-permissions-editor.component';
import {
  WorkflowService,
  WorkflowContextService,
  SecurityService,
  ActivityFactory
} from '../../../../services';
import { ActivatedRoute } from '@angular/router';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivityEditorBaseComponent } from '../activity-editor-base/activity-editor-base.component';
import { SavingChange } from 'src/app/models/saving-change';
import { ConditionTarget } from 'src/app/components/system/condition-builder/condition-builder.model';

@Component({
  selector: 'wm-activity-editor',
  templateUrl: './activity-editor.component.html',
  styleUrls: ['./activity-editor.component.css']
})
export class ActivityEditorComponent implements OnInit, OnDestroy {
  @Input() activityId: string;
  @Input() activityType: string;
  @Input() form: UntypedFormGroup;
  @Input() clientId: string;
  @Input() savingChanges: SavingChange[];
  @Output() needsValidated: EventEmitter<any> = new EventEmitter<any>();

  activities: Activity<ActivityModel>[];
  @Input() activity: Activity<ActivityModel>;
  shouldShowAdvancePermissions = false;
  Actions: Actions = Actions;
  roles: Role[];
  responsibleRole: AbstractControl;
  activityName: AbstractControl;
  componentRef: ComponentRef<ActivityEditorBaseComponent>;
  @Output() saved: EventEmitter<any> = new EventEmitter<any>();

  get showResponsibleRole(): boolean {
    return this.activity ? this.activity.isResponsibleRoleRequired : false;
  }
  @ViewChild('advancedPermissions', {
    read: AdvancedPermissionsEditorComponent,
    static: false
})
  advancedPermissions: AdvancedPermissionsEditorComponent;
  @ViewChild('activityTypeEditor', { read: ViewContainerRef, static: true })
  activityEditor: ViewContainerRef;

  showHelpText = true;

  loading = true;
  isDestroyed = false;
  roleIsApplicant = false;

  constructor(
    private _workflowSvc: WorkflowService,
    private _context: WorkflowContextService,
    private componentFactoryResolver: ComponentFactoryResolver,
    public SecuritySvc: SecurityService,
    private route: ActivatedRoute,
    private _fb: UntypedFormBuilder,
    private _ref: ChangeDetectorRef
  ) {
    if (!this.form) {
      this.form = _fb.group({
        responsibleRole: ['', Validators.required],
        activityName: ['', Validators.required],
        screenHelpText: null
      });
    }

    this.responsibleRole = this.form.controls['responsibleRole'];
    this.activityName = this.form.controls['activityName'];
  }

  getThenLabel = (clause: ConditionTarget): string => {
    const value = clause.value;

    return value.replace('${', '<span class="de">').replace('}', '</span>');
  };

  detectChanges() {
    if (!this.isDestroyed) {
      this._ref.detectChanges();
    }
  }


  updateDisplayLabel(criteria: ConditionTarget[]) {
    this.activity.displayLabel = criteria;
    let hasValue = false;

    if (criteria && criteria.length > 0) {
      criteria.forEach((c, index) => {
        if ((c.value || '') !== '') {
          hasValue = true;
          return false;
        }
      });
    }
    this.form.controls['displayLabel'].setValue(hasValue ? 'done' : '');
    this.detectChanges();
  }

  ngOnDestroy(): void {
    this.isDestroyed = true;
  }

  showAdvancedPermissions() {
    this.shouldShowAdvancePermissions = !this.shouldShowAdvancePermissions;
  }

  getRoleById(roleId: string): Role {
    let role: Role;
    if (roleId) {
      this.roles.some((value: Role) => {
        if (value.id.toUpperCase() === roleId.toUpperCase()) {
          role = value;
          return true;
        }
      });
    }
    return role;
  }

  selectRole($event) {
    if ($event) {
      const roleId = $event.srcElement.value.toUpperCase();
      const sRole = this.getRoleById(roleId);
      const model: ActivityModel = (<ScreenActivity<ActivityModel>>(
        this.activity
      )).model;

      if (sRole) {
        sRole.id = roleId;
        model.responsibleRole = sRole;
        if (model.responsibleRole) {
          model.responsibleRoleId = roleId;
        }
      }
    }
  }

  clearPermissions() {
    this.activity.permissions = {};
  }

  refreshPermissions() {
    if (this.advancedPermissions) {
      this.advancedPermissions.refreshPermissions();
    }
  }

  save() {
    if (this.componentRef) {
      return this.componentRef.instance.save();
    }

    return false;
  }

  ngOnInit() {
    if (this.form) {
      this.form.addControl(
        'responsibleRole',
        this._fb.control(this.activity.model.responsibleRoleId, [
          Validators.required
        ])
      );
      this.form.addControl(
        'activityName',
        this._fb.control('', [Validators.required, Validators.maxLength(100)])
      );
      this.form.addControl(
        'screenHelpText',
        this._fb.control('', [Validators.nullValidator])
      );
      this.form.addControl(
        'visibleToPublic',
        this._fb.control('', [Validators.nullValidator])
      );
      this.form.addControl(
        'displayLabel',
        new UntypedFormControl('', [Validators.nullValidator])
      );
    }

    this.route.params.subscribe(params => {
      this.activityId = params['activityId'] || this.activityId;
      this.activityType = params['activityType'] || this.activityType;

      if (this.activityId) {
        this.SecuritySvc.getRolesWithAnyActions(
          this.clientId ||
            (this._context.client ? this._context.client.id : null),
          Actions.RESPONSIBLE_ROLE_ACTIONS
        ).subscribe(async roles => {
          if (
            roles.filter((value: Role) => {
              return value.id === SecurityService.APPLICANT_ROLE.id;
            }).length === 0
          ) {
            roles.splice(0, 0, SecurityService.APPLICANT_ROLE);
          }

          this.roles = roles;
          const that = this;

          const activity =
            this.activity ||
            this._workflowSvc.getWorkflowActivity(
              this._context.workflow,
              this.activityId
            );

          // Make a call to load any details necessary for this editor
          // if (activity.needsEditorDataLoaded) {
          let isEditorSaving = false;
          let savingEditor = null;

          if (this.savingChanges) {
            savingEditor = this.savingChanges.find(
              f => f.changedActivity && f.changedActivity.id === this.activityId
            );
            if (savingEditor) {
              isEditorSaving = true;
            }
          }

          let activityEditorData: Activity<ActivityModel>;

          if (!isEditorSaving) {
            activityEditorData = await this._workflowSvc
              .getActivityEditor(
                this._context.workflow.id,
                this.activityId,
                !this._context.workflow.version.isDraft
                  ? this._context.workflow.version.id
                  : null
              )
              .toPromise();
          } else {
            activityEditorData = savingEditor;
          }

          if (activityEditorData) {
            // set the current activity object to the editor loaded object if it returned a value
            Object.assign(activity, activityEditorData);
          }
          // }

          if (activity) {
            const o: any = activity;

            if (!that.showResponsibleRole) {
              this.form.controls['responsibleRole'].disable();
              activity.model.responsibleRoleId = SecurityService.APPLICANT_ROLE.id.toLowerCase();
            } else {
              this.form.controls['responsibleRole'].enable();
            }

            const a$ = that._context.activeActivity$.subscribe(
              activeActivity => {
                a$.unsubscribe();

                that.refreshPermissions();
              }
            );

            const responsibleRole: Role = that.getRoleById(
              activity.model.responsibleRoleId
            );

            if (responsibleRole) {
              responsibleRole.id = responsibleRole.id.toUpperCase();
              activity.model.responsibleRole = responsibleRole;
              activity.model.responsibleRoleId = responsibleRole.id;
            }

            if (!this.isDestroyed) {
              that._context.activeActivity$.next(activity);
            }

            this.loading = false;
            that.activity = activity;
            this.loadEditor(this.activityType);
          }

          if (this.activity) {
            this.roleIsApplicant =
              (this.activity.model.responsibleRoleId ||
                SecurityService.APPLICANT_ROLE.id) ===
              SecurityService.APPLICANT_ROLE.id;
          } else {
            this.roleIsApplicant = true;
          }
        });
      }
    });
  }

  loadEditor(type: string) {
    let compType: Type<ActivityEditorBaseComponent>;

    if (this.componentRef) {
      this.componentRef.destroy();
    }

    this.activityEditor.clear();

    compType = ActivityFactory.getMiniEditComponent(type);

    if (compType && this.activityEditor) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory<
        ActivityEditorBaseComponent
      >(compType);

      this.componentRef = this.activityEditor.createComponent(componentFactory);
      this.componentRef.instance.form = this.form;
      this.componentRef.instance.activity = this.activity;
      this.componentRef.instance.saved.subscribe(e => {
        this.saved.emit(e);
      });

      this.componentRef.instance.loading.subscribe(e => {
        this.loading = e;
      });

      this.componentRef.instance.needsValidated = this.needsValidated;
    }
  }
}
