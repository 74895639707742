import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { BulkAction } from '../../../../system/datatable/datatable.component';
import {
  Activity,
  ActivityModel,
  DataEntityLayoutModel,
  FormActivity,
  SingleColumnFormLayoutModel
} from '../../../../../models/activities';
import { Client, Workflow } from '../../../../../models';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ClientService,
  DataEntityFactory,
  SecurityService,
  WorkflowContextService,
  WorkflowService
} from '../../../../../services';
import { customAlphabet } from 'nanoid';
import { ParentReference } from '../../../../../models/data-entities';
import * as _ from 'lodash';
import { combineLatest, map, Observable, of } from 'rxjs';

export interface MoveDataEntityConfig {
  bulkAction: BulkAction;
  activityId: string;
}
const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ', 6);

@Component({
  selector: 'wm-form-activity-data-entities-bulk-actions',
  templateUrl: './form-activity-data-entities-bulk-actions.component.html',
  styleUrls: ['./form-activity-data-entities-bulk-actions.component.css']
})
export class FormActivityDataEntitiesBulkActionsComponent implements OnInit {
  activityForm: FormGroup;
  moveForm: FormGroup;
  selectedDEs = {};
  selectedActionType = 'move';
  selected = false;
  @Input() public dataEntities: DataEntityLayoutModel[] = [];
  @Input() workflow: Workflow;
  @Input() activity: Activity<ActivityModel>;
  @Output() saveActivity: EventEmitter<
    Activity<ActivityModel>
  > = new EventEmitter<Activity<ActivityModel>>();

  selectedActivityId: string;
  selectedJurisdiction: string;
  selectedWorkflow: string;

  clients: Client[];
  clientsIdx: { [key: string]: Client };

  workflows: Workflow[];
  workflowIdx: { [key: string]: Workflow };

  activities: Activity<ActivityModel>[];
  isSysAdmin: boolean;

  public bulkActions: BulkAction[] = [];

  get moveLabel() {
    if (this.moveForm.controls['bulkAction'].value) {
      return this.moveForm.controls['bulkAction'].value.id === 'move'
        ? 'Move'
        : 'Copy';
    }
    return 'Copy';
  }

  opened = false;

  constructor(
    private _fb: FormBuilder,
    private modalService: NgbModal,
    public context: WorkflowContextService,
    public _workflowSvc: WorkflowService,
    public _clientSvc: ClientService,
    public _securitySvc: SecurityService
  ) {
    this.activityForm = this._fb.group({});
  }
  onOpen() {
    this.selectedWorkflow = null;
    this.initSelectedDes();

    this.opened = true;

    for (const key in this.selectedDEs) {
      if (this.selectedDEs[key]) {
        this.selectedDEs[key].selected = false;
      }
    }

    let ops = [];

    if (this.isSysAdmin) {
      if(this.context.client) {
        this.selectedJurisdiction = this.context.client.id;
      }

      ops.push(
        this._clientSvc.getClients().pipe(
          map(results => {
            this.clients = results;
            this.clientsIdx = {};

            results.forEach(c => {
              this.clientsIdx[c.id] = c;
            });
          })
        )
      );
    }

    ops.push(this.loadWorkflows(this.context.client));

    combineLatest(ops).subscribe();
  }

  get isPerformingCopy() {
    return this.moveForm.controls['bulkAction'].value.id == 'copy-to-workflow';
  }

  isSystemAdmin() {
    this._securitySvc.isSystemAdministrator().subscribe(result => {
      this.isSysAdmin = result;
    });
  }

  loadWorkflowActivities(workflowVersionId: string) {
    if (workflowVersionId) {
      const workflow = this.workflowIdx[workflowVersionId];
      if (workflow) {
        this.activities = [];
        this._workflowSvc
          .getWorkflowActivitiesById(workflow.id, [
            WorkflowService.ACTIVITIES.Form
          ])
          .subscribe(activities => {
            this.activities = activities;
          });
      }
    }
  }

  loadWorkflows(client: Client): Observable<Workflow[]> {
    if (client) {
      this.workflows = [];
      this.workflowIdx = null;

      return this._workflowSvc.getWorkflows(client, false).pipe(
        map(results => {
          this.workflows = results;
          this.workflowIdx = {};
          if (results) {
            results.forEach(w => {
              this.workflowIdx[w.version.id] = w;
            });
          }
          return results;
        })
      );
    } else {
      return of();
    }
  }

  onCancel() {
    this.opened = false;

    for (const key in this.selectedDEs) {
      if (this.selectedDEs[key]) {
        this.selectedDEs[key].selected = false;
      }
    }
  }
  onSave() {
    this.opened = false;
  }

  async doBulkAction() {
    const v = this.moveForm.value as MoveDataEntityConfig;
    await v.bulkAction.action([]);
  }
  isEntitySelected(id) {}
  toggleEntity(event, de) {
    const isChecked: boolean = event.target['checked'];
    const templateCode: string = event.target['value'];
    this.selectedDEs[templateCode] = { selected: isChecked, de: de };
  }
  selectOneResult(p: any, v: any) {}
  initBulkActions() {
    this.bulkActions.push({
      name: 'Copy',
      id: 'copy',
      action: async boolean => {
        this.move();
        return false;
      }
    });
    this.bulkActions.push({
      name: 'Move',
      id: 'move',
      action: async boolean => {
        this.move();
        return false;
      }
    });
    this.bulkActions.push({
      name: 'Copy to Workflow',
      id: 'copy-to-workflow',
      action: async boolean => {
        this.move();
        return false;
      }
    });
  }
  get formActivities() {
    return (
      this.activities ||
      this.workflow.version.graph
        .getActivities(this.workflow.designStatus)
        .filter(
          a =>
            a instanceof FormActivity &&
            this.moveForm.controls['bulkAction'].value != null &&
            (this.moveForm.controls['bulkAction'].value.id === 'copy' ||
              a.id !== this.activity.id)
        )
    );
  }
  initSelectedDes() {
    this.selectedDEs = {};

    // remove System DE's from entities that can be copied.
    this.dataEntities = this.dataEntities.filter(de => {
      return !de.entity.isSystemEntity;
    });

    this.dataEntities.forEach(de => {
      if (de && de.entity && de.entity.templateCode) {
        this.selectedDEs[de.entity.templateCode] = { selected: false };
      }
    });
  }
  ngOnInit() {
    this.activityForm = new FormGroup({});

    this.initBulkActions();
    this.initSelectedDes();
    this.moveForm = new FormGroup({
      activityId: new FormControl(null, [Validators.required]),
      bulkAction: new FormControl(this.bulkActions[1], [Validators.required]),
      jurisdiction: new FormControl(null, [Validators.nullValidator]),
      workflow: new FormControl(null, [Validators.nullValidator]),
      selected: new FormControl(null)
    });

    this.isSystemAdmin();
  }
  dismiss() {
    this.modalService.dismissAll('cancel');
  }
  getFormModel(activity: FormActivity): SingleColumnFormLayoutModel {
    return activity.model.formLayoutModel as SingleColumnFormLayoutModel;
  }
  createCopy(
    newActivity: FormActivity,
    moveConfig: MoveDataEntityConfig,
    de: any
  ) {
    const newDe = DataEntityFactory.createDataEntity(
      de.entity.dataEntityTypeCode,
      _.cloneDeep(de.entity)
    );
    if (newDe) {
      newDe.parent = new ParentReference({ id: newActivity.id });
      if (moveConfig.bulkAction.id === 'copy') {
        newDe.templateCode = `${newDe.templateCode}_COPY_${nanoid()}`;
      }
    }
    return newDe;
  }

  move() {
    const moveConfig = this.moveForm.value;
    if (
      !this.selectedWorkflow ||
      this.selectedWorkflow == this.workflow.version.id
    ) {
      this._workflowSvc
        .getActivityEditor(
          this.workflow.id,
          moveConfig.activityId,
          !this.workflow.version.isDraft ? this.workflow.version.id : null
        )
        .subscribe(activity => {
          const newActivity: FormActivity = activity as FormActivity;
          const newFormModel = this.getFormModel(newActivity);
          for (const key in this.selectedDEs) {
            if (this.selectedDEs[key]) {
              if (this.selectedDEs[key].selected === true) {
                const de = this.selectedDEs[key].de;
                const newDe = this.createCopy(newActivity, moveConfig, de);
                //       order so the largest displayOrder is first
                newDe['allowTemplateCodeEdit'] = true;

                const orderedEntities = newFormModel.columnEntities.sort(
                  (a, b) => b.displayOrder - a.displayOrder
                );

                newFormModel.columnEntities.push(
                  new DataEntityLayoutModel({
                    entity: newDe || undefined,
                    displayOrder:
                      orderedEntities && orderedEntities.length > 0
                        ? orderedEntities[0].displayOrder + 1
                        : 0
                  })
                );

                if (moveConfig.bulkAction.id === 'move') {
                  const oldFormModel = this.getFormModel(
                    this.activity as FormActivity
                  );
                  oldFormModel.columnEntities = oldFormModel.columnEntities.filter(
                    a => a.entity.templateCode !== de.entity.templateCode
                  );
                }
              }
            }
          }
          // trigger a save for the destination activity if we are copying/moving to another activity.
          this.saveActivity.emit(newActivity);

          // trigger a save for the current activity if we are moving to another activity.
          if (
            newActivity.id !== this.activity.id &&
            moveConfig.bulkAction.id === 'move'
          ) {
            this.saveActivity.emit(this.activity);
          }

          // all done!
          this.resetMoveForm();
          this.modalService.dismissAll('saved');
        });
    } else {
      // copy the selected entities to the target workflow activity
      this._workflowSvc
        .copyEntityToOtherWorkflow({
          targetClientId: this.context.client.id,
          sourceWorkflowVersionId: this.workflow.version.id,
          targetWorkflowVersionId: this.selectedWorkflow,
          sourceActivityId: this.activity.id,
          targetActivityId: moveConfig.activityId,
          templateCodes: Object.keys(this.selectedDEs)
        })
        .subscribe(result => {
          this.resetMoveForm();
          this.modalService.dismissAll('saved');
        });
    }
  }
  selectedDe(templateCode) {
    return this.selectedDEs[templateCode];
  }
  resetMoveForm() {
    this.initSelectedDes();
    this.moveForm.patchValue({ bulkActions: this.bulkActions[1] });
  }
}
