<form [formGroup]="form" data-toggle="validator">
  <div class="row">
    <div class="col-md-6">
      <h3>Email Settings</h3>
      <app-loader *ngIf="!client"></app-loader>
      <div *ngIf="client">
        <div class="card">
          <div class="card-body">
            <h3>Custom From Domain</h3>
            <div class="text-danger">
              IMPORTANT NOTE: In order for this to get setup, someone will need to be able to modify the DNS records for the domain that they want to use.
            </div>
            <div class="form-group">
              <label for="customFromDomain" class="bmd-label-floating"
                >Domain</label
              >
              <input
                type="text"
                class="form-control required"
                formControlName="customFromDomain"
                [(ngModel)]="client.customFromDomain"
                name="customFromDomain"
              />
              <wm-control-message
                [control]="form.controls['customFromDomain']"
              ></wm-control-message>
            </div>
            <div class="form-group">
              <button
                class="btn btn-raised btn-primary"
                (click)="save().subscribe()"
              >
                Save
              </button>
              <button
                class="ml-2 btn btn-raised btn-default"
                (click)="validateCustomFromDomain()"
                [disabled]="!client.customFromDomain"
              >
                Validate Email
              </button>
              <button
                class="ml-2 btn btn-raised btn-default"
                (click)="enableDomainForTesting()"
                [disabled]="!client.customFromDomain"
                *ngIf="!isCustomFromTestingEnabled"
              >
                Enable Domain for Testing
              </button>
              <button
                class="ml-2 btn btn-raised btn-default"
                (click)="disableDomainForTesting()"
                [disabled]="!client.customFromDomain"
                *ngIf="isCustomFromTestingEnabled"
              >
                Disable Domain for Testing
              </button>
            </div>
            <div
              *ngIf="
                customFromDomainResult &&
                customFromDomainResult.messages &&
                customFromDomainResult.messages.length > 0
              "
            >
              <div [ngClass]="{'text-danger': !customFromDomainResult.valid, 'text-success': customFromDomainResult.valid }">Validation Messages</div>
              <ul class="list-group" [ngClass]="{'text-danger': !customFromDomainResult.valid, 'text-success': customFromDomainResult.valid }">
                <li
                  class="list-group-item"
                  *ngFor="let m of customFromDomainResult.messages"
                >
                  {{ m }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
